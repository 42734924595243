import * as React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { useLoginAppContextValue } from '../../../stateManagement/LoginAppContext';
import { LoginPageDictionary, TypeKeys } from '../../../stateManagement/types';

import { styles, Button, Input } from '@onehope/design-system';
const { styled: s } = styles;

type SubmitNameParams = {
  form: any;
  validateFields: any;
  dispatch: any;
};

function determineNextPage(dispatch: any) {
  return dispatch({
    type: TypeKeys.CHANGE_PAGE,
    page: LoginPageDictionary.ENTER_AGE,
  });
}

function submitName({ form, validateFields, dispatch }: SubmitNameParams) {
  return () =>
    validateFields(
      (err: any, values: { firstName: string; lastName: string }) => {
        if (!err) {
          const { firstName, lastName } = values;
          if (!firstName) {
            return form.setFields({
              firstName: {
                value: form.getFieldValue('firstName'),
                errors: [new Error('Please enter first name')],
              },
            });
          }
          if (!lastName) {
            return form.setFields({
              lastName: {
                value: form.getFieldValue('lastName'),
                errors: [new Error('Please enter first name')],
              },
            });
          }
          dispatch({
            type: TypeKeys.UPDATE_NAME,
            firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
            lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
          });
          return determineNextPage(dispatch);
        }
      },
    );
}

type NamePageProps = FormComponentProps & {};

function goToSelectLoginMethod(dispatch: any) {
  return () => {
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.ENTER_EMAIL,
    });
  };
}

const firstNameValidationConfig = (initialValue: string) => {
  return {
    validateTrigger: 'onBlur',
    validateFirst: true /*stop validate on first rule of error for this field. */,
    rules: [
      {
        required: true,
        message: 'Please input your first name',
      },
    ],
    initialValue,
  };
};

const lastNameValidationConfig = (initialValue: string) => {
  return {
    validateTrigger: 'onBlur',
    validateFirst: true /*stop validate on first rule of error for this field. */,
    rules: [
      {
        required: true,
        message: 'Please input your last name',
      },
    ],
    initialValue,
  };
};

const NamePageComponent = ({ form }: NamePageProps) => {
  const { getFieldDecorator, validateFields } = form;
  const [state, dispatch] = useLoginAppContextValue();
  const dispatchVerifyName = submitName({
    form,
    validateFields,
    dispatch,
  });

  const {
    signUpFlow: { firstName, lastName },
  } = state;
  const dispatchGoToSelectLoginMethod = goToSelectLoginMethod(dispatch);
  return (
    <React.Fragment>
      <s.LoginHeader css>What's your name?</s.LoginHeader>
      <Form>
        <Form.Item hasFeedback>
          {getFieldDecorator(
            'firstName',
            // @ts-ignore: says that config does not match up with typings
            firstNameValidationConfig(firstName),
          )(
            // @ts-ignore
            <Input
              autoFocus
              type="text"
              name="firstName"
              autoComplete="name"
              label="First Name"
              kind="text"
            />,
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator(
            'lastName',
            // @ts-ignore: says that config does not match up with typings
            lastNameValidationConfig(lastName),
          )(
            // @ts-ignore
            <Input
              type="text"
              name="lastName"
              autoComplete="name"
              label="Last Name"
              kind="text"
            />,
          )}
        </Form.Item>
        <s.ButtonWrapper css>
          <Button
            block
            type="primary-regular"
            htmlType="submit"
            onClick={dispatchVerifyName}
          >
            Next
          </Button>
          <Button
            type="tertiary-regular"
            onClick={dispatchGoToSelectLoginMethod}
          >
            Back
          </Button>
        </s.ButtonWrapper>
      </Form>
    </React.Fragment>
  );
};

export default Form.create({
  name: 'name_page',
})(NamePageComponent);
