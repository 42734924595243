// @ts-ignore
import * as React from 'react';
import queryString from 'query-string';
import { useLoginAppContextValue } from '../../stateManagement/LoginAppContext';
import { LoginPageDictionary, TypeKeys } from '../../stateManagement/types';
import { styles } from '@onehope/design-system';
import { useLoginNetworkLayerValue } from '../../LoginNetworkLayer';
import Buttons from './Buttons';

const { styled: s } = styles;

export default function SelectLoginMethod() {
  const [{}, dispatch] = useLoginAppContextValue();
  const { loginHeader, loginText } = useLoginNetworkLayerValue();

  if (typeof window !== 'undefined') {
    const { step } = queryString.parse(window.location.search);

    function goToForgotPassword() {
      return dispatch({
        type: TypeKeys.CHANGE_PAGE,
        page: LoginPageDictionary.FORGOT_PASSWORD,
      });
    }

    React.useEffect(() => {
      if (step && step === 'FORGOT_PASSWORD') {
        goToForgotPassword();
      }
    }, []);
  }

  const header = loginHeader || 'Hello there.';
  const text = loginText || 'Login or Register using one of the following:';
  return (
    <>
      <s.LoginHeader css>{header}</s.LoginHeader>
      <s.LoginText css>{text}</s.LoginText>
      <Buttons />
    </>
  );
}
