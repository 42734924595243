import gql from 'graphql-tag';

const GET_CUSTOMER_ORDERS_QUERY = gql`
  query ADash_CustomerOrdersQuery($accountId: String!, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        customerOrders(first: 1000)
          @connection(key: "Customer_orders", filter: []) {
          edges {
            __typename
            node {
              id
              orderId
              orderStatus
              orderNumber
              orderTotal
              createDate
              email
              trinityTransmissionStatus
              trinityOrderId
              trinityPartyId
              accountFullName
              shippingInfo {
                carrier
                tracking
                expectedDeliveryDate
              }
              giftMessage {
                message
                recipientName
                from
              }
              shippingAddress {
                firstName
                lastName
                specialInstructions
                street
                addressLineTwo
                addressLineOne
                city
                state
                zip
                phone
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export default GET_CUSTOMER_ORDERS_QUERY;
