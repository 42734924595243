import gql from 'graphql-tag';

const GET_ORDERS_QUERY = gql`
  query ADash_OrdersQuery(
    $accountId: String!
    $csPortal: Boolean!
    $sortBy: String!
    $filterBy: String!
    $cursor: String
  ) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        orders(
          first: 1000
          sortBy: $sortBy
          filterBy: $filterBy
          csPortal: $csPortal
          after: $cursor
        ) @connection(key: "Orders_orders", filter: []) {
          edges {
            __typename
            node {
              id
              orderId
              orderNumber
              accountFullName
              createDate
              orderStatus
              orderTotal
              orderTax
              orderSubTotal
              email
              giftMessage {
                message
                recipientName
                from
              }
              giftReceipt
              isProcessing
              giftCards
              giftCardSavings
              promoCodes
              promoCodeSavings
              trinityTransmissionStatus
              trinityOrderId
              trinityPartyId
              shippingChosen
              shippingInfo {
                carrier
                tracking
                expectedDeliveryDate
              }
              shippingAddress {
                firstName
                lastName
                specialInstructions
                street
                addressLineOne
                addressLineTwo
                city
                state
                zip
                phone
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export default GET_ORDERS_QUERY;
