import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  font,
  tertiaryFont,
  primaryColor,
  disabledColor,
  primaryHoverColor,
  linkColor,
  neutral34,
  defaultHoverColor,
  linkHoverColor,
  neutral10,
  neutral13,
} from '../../styles/cssConstants';

const baseStyle = css`
  background-color: ${primaryColor};
  font-family: ${font};
  height: 40px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0.08em;;
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ButtonPrimary = styled(Button)`
  && {
    ${baseStyle};
    color: ${({ disabled }: ButtonProps) =>
      disabled ? 'white !important' : 'white'};
    background-color: ${({ disabled }: ButtonProps) =>
      disabled ? disabledColor : primaryColor};
    :hover {
      background-color: ${({ disabled }: ButtonProps) =>
        disabled ? disabledColor : primaryHoverColor};
    }
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ButtonRound = styled(Button)`
  && {
    ${baseStyle};
    height: 32px;
    text-transform: unset;
    border-radius: 100px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${({ disabled }: ButtonProps) =>
      disabled ? 'white !important' : 'white'};
    background-color: ${({ disabled }: ButtonProps) =>
      disabled ? disabledColor : primaryColor};
    :hover {
      background-color: ${({ disabled }: ButtonProps) =>
        disabled ? disabledColor : primaryHoverColor};
    }
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ButtonBlue = styled(Button)<ButtonProps>`
  && {
    ${baseStyle};
    color: ${({ disabled }: ButtonProps) =>
      disabled ? 'white !important' : 'white'};
    background-color: ${({ disabled }: ButtonProps) =>
      disabled ? disabledColor : linkColor};
    :hover {
      background-color: ${({ disabled }: ButtonProps) =>
        disabled ? disabledColor : linkHoverColor};
    }
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ButtonSecondary = styled(Button)<ButtonProps>`
  && {
    ${baseStyle};
    background-color: white;
    border: ${({ disabled }: ButtonProps) =>
      disabled ? `1px solid ${neutral34}` : `1px solid ${primaryColor}`};
    color: ${({ disabled }: ButtonProps) =>
      disabled ? neutral34 : primaryColor};
    :hover {
      background-color: ${({ disabled }: ButtonProps) =>
        disabled ? 'white ' : defaultHoverColor};
    }
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ButtonGrey = styled(Button)<ButtonProps>`
  && {
    ${baseStyle};
    font-family: ${tertiaryFont};
    background-color: ${({ disabled }: ButtonProps) =>
    disabled ? neutral34 : neutral10};
    border: ${({ disabled }: ButtonProps) =>
    disabled ? `1px solid ${neutral34}` : `1px solid ${neutral10}`};
    color: ${({ disabled }: ButtonProps) =>
    disabled ? neutral13 : primaryColor};
    :hover {
      background-color: ${({ disabled }: ButtonProps) =>
    disabled ? neutral34 : neutral10};
    }
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ButtonTertiary = styled(Button)<ButtonProps>`
  && {
    ${baseStyle};
    background-color: white;
    color: ${({ disabled }: ButtonProps) =>
      disabled ? neutral34 : primaryColor};
    :hover {
      background-color: white;
    }
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ButtonDashed = styled(Button)<ButtonProps>`
  && {
    ${baseStyle};
    height: 100px;
    background-color: white;
    border: ${({ disabled }: ButtonProps) =>
      disabled ? `1px dashed ${neutral34}` : `1px dashed ${primaryColor}`};
    color: ${({ disabled }: ButtonProps) =>
      disabled ? neutral34 : primaryColor};
    :hover {
      background-color: ${({ disabled }: ButtonProps) =>
        disabled ? 'white ' : defaultHoverColor};
    }
  }
`;

export enum MuiButtonTypes {
  TERTIARY = 'TERTIARY',
  SECONDARY = 'SECONDARY',
  BLUE = 'BLUE',
  GREY = 'GREY',
  PRIMARY = 'PRIMARY',
  DASHED = 'DASHED',
  ROUND = 'ROUND',
}

type MuiButton = ButtonProps & {
  type: string;
};

export default function MuiButton(props: MuiButton) {
  const { type, ...rest } = props;
  switch (type as any) {
    case MuiButtonTypes.TERTIARY: {
      return <ButtonTertiary {...rest} />;
    }
    case MuiButtonTypes.SECONDARY: {
      return <ButtonSecondary {...rest} />;
    }
    case MuiButtonTypes.GREY: {
      return  <ButtonGrey {...rest} />;
    }
    case MuiButtonTypes.BLUE: {
      return <ButtonBlue {...rest} />;
    }
    case MuiButtonTypes.DASHED: {
      return <ButtonDashed {...rest} />;
    }
    case MuiButtonTypes.ROUND: {
      return <ButtonRound {...rest} />;
    }
    default: {
      return <ButtonPrimary {...rest} fullWidth />;
    }
  }
}
