type PasswordValidationPartial = {
  value: any;
  callback: (input?: string) => void;
};

type PasswordValidationComparisonParam = {
  form: any;
} & PasswordValidationPartial;

export function compareToFirstPassword({
  value,
  callback,
  form,
}: PasswordValidationComparisonParam) {
  if (value && value !== form.getFieldValue('password')) {
    callback('Passwords do not match!');
  } else {
    callback();
  }
}

export function checkPassword({ value, callback }: PasswordValidationPartial) {
  const passwordWithNumber = /^(?=.*\d).{6,}$/;
  const passwordWithSymbol = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
  if (
    value &&
    (!value.match(passwordWithNumber) && !value.match(passwordWithSymbol))
  ) {
    callback('Passwords must include: 6+ characters, 1 symbol or number');
  }
  callback();
}

export function validateToNextPassword({
  value,
  callback,
  form,
}: PasswordValidationComparisonParam) {
  if (value) {
    form.validateFields(['confirm'], { force: true });
  }
  callback();
}

export const passwordRequired = {
  required: true,
  message: 'Please input your password!',
};

export const passwordValidationConfig = (form: any) => {
  return {
    validateTrigger: 'onBlur',
    validateFirst: true /*stop validate on first rule of error for this field. */,
    rules: [
      passwordRequired,
      {
        // @ts-ignore rule unused
        validator: (rule: any, value: any, callback: () => void) =>
          validateToNextPassword({ value, callback, form }),
      },
      {
        // @ts-ignore rule unused
        validator: (rule: any, value: any, callback: () => void) =>
          checkPassword({ value, callback }),
      },
    ],
  };
};

export const validateToNextPasswordConfig = (form: any) => {
  return {
    validateTrigger: 'onBlur',
    validateFirst: true /*stop validate on first rule of error for this field. */,
    rules: [
      {
        required: true,
        message: 'Please confirm your password!',
      },
      {
        // @ts-ignore rule unused
        validator: (rule: any, value: any, callback: () => void) =>
          compareToFirstPassword({ value, callback, form }),
      },
    ],
  };
};
