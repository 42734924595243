import { navigate } from 'gatsby';
import ApolloClient from 'apollo-client';
import { FormComponentProps } from 'antd/lib/form';
import { Errors } from '@onehope/login';
import UserLoginMutation, {
  LoginMutationVariables,
} from '../mutations/Authentication/LoginMutation';

import AccountResetPassword, {
  ResetPasswordMutationVariables,
} from '../mutations/Account/AccountResetPassword';

import AccountPasswordRecovery, {
  AccountPasswordRecoveryMutationVariables,
} from '../mutations/Account/AccountPasswordRecovery';

import RegisterMutation, {
  RegisterMutationVariables,
} from '../mutations/Authentication/RegisterMutation';
import VerifyEmail from '../queries/VerifyEmail';

export function getQueryStringValue(key: string, location: any) {
  return decodeURIComponent(
    location.search.replace(
      new RegExp(
        '^(?:.*[&\\?]' +
          encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
          '(?:\\=([^&]*))?)?.*$',
        'i',
      ),
      '$1',
    ),
  );
}

export type VerifyEmailPayload = {
  hasFacebook: boolean;
  hasGoogle: boolean;
  isAccount: boolean;
  hasPassword: boolean;
  isCE: boolean;
  isAdmin: boolean;
  firstName: string | null;
};

export type VerifyEmailAndVerifyPayload = {
  verifyEmail: VerifyEmailPayload;
  hasError: boolean;
};

export type VerifyEmailData = {
  data: { viewer: { verifyEmail: VerifyEmailPayload } };
};

export function verifyEmail(apolloClient: ApolloClient<any>) {
  return (
    email: string,
    form?: FormComponentProps<{ email: string }>['form'],
  ): Promise<VerifyEmailAndVerifyPayload> => {
    return apolloClient
      .query({ query: VerifyEmail, variables: { email } })
      .then(({ data }: VerifyEmailData) => {
        const {
          viewer: {
            verifyEmail: {
              firstName,
              hasFacebook,
              hasGoogle,
              isAccount,
              hasPassword,
              isCE,
              isAdmin,
            },
          },
        } = data;
        let hasError = false;
        if (!isAdmin && form) {
          form.setFields({
            email: {
              value: form.getFieldValue('email'),
              errors: [new Error(Errors.AdminPermissionsRequired)],
            },
          });
          hasError = true;
        }
        return {
          verifyEmail: {
            firstName,
            hasFacebook,
            hasGoogle,
            isAccount,
            hasPassword,
            isCE,
            isAdmin,
          },
          hasError,
        };
      });
  };
}

export function registerMutation(apolloClient: ApolloClient<any>) {
  return (variables: RegisterMutationVariables) => {
    return apolloClient
      .mutate({ mutation: RegisterMutation, variables })
      .then(({ data }: any) => {
        console.log({ data });
      })
      .catch(error => {
        return { error: error.graphQLErrors[0].message };
      });
  };
}

export function loginMutation(
  apolloClient: ApolloClient<any>,
  redirectUri: string,
) {
  return (variables: LoginMutationVariables) => {
    return apolloClient
      .mutate({ mutation: UserLoginMutation, variables })
      .then(({ data }: any) => {
        apolloClient.resetStore().then(() => {
          if (redirectUri) {
            navigate(redirectUri);
          }
        });
      })
      .catch(error => {
        console.log({ error });
        return { error: error.graphQLErrors[0].message };
      });
  };
}

export function resetPasswordMutation(apolloClient: ApolloClient<any>) {
  return (variables: ResetPasswordMutationVariables) => {
    return apolloClient
      .mutate({ mutation: AccountResetPassword, variables })
      .then(({ data }: any) => {
        console.log('pass reest', data);
      })
      .catch(error => {
        return { error: error.graphQLErrors[0].message };
      });
  };
}

export function sendRecoveryEmailMutation(apolloClient: ApolloClient<any>) {
  return (variables: AccountPasswordRecoveryMutationVariables) => {
    return apolloClient
      .mutate({ mutation: AccountPasswordRecovery, variables })
      .then(({ data }: any) => {
        console.log('email sent', data);
      })
      .catch(error => {
        return { error: error.graphQLErrors[0].message };
      });
  };
}

export const suffix = `please email support@onehopewine.com`;
