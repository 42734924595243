import * as React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { useLoginNetworkLayerValue } from '../../LoginNetworkLayer';
import { useLoginAppContextValue } from '../../stateManagement/LoginAppContext';
import { LoginPageDictionary, TypeKeys } from '../../stateManagement/types';
import { validateAndSubmitLogin } from '../../utils/validateAndSubmitLogin';

import { styles, Button, Input } from '@onehope/design-system';
const {
  styled: s,
  antDesignStyles: {
    checkBox: { CheckBoxPrimary },
  },
} = styles;
const { useState } = React;
type EnterPasswordProps = FormComponentProps & {};

function goToSelectEnterEmailPage(dispatch: any) {
  return () => {
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.ENTER_EMAIL,
    });
  };
}

function goToForgotPassword(dispatch: any) {
  return () =>
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.FORGOT_PASSWORD,
    });
}

const passwordValidationConfig = {
  validateTrigger: 'onBlur',
  rules: [{ required: true, message: 'Please input your password' }],
};

function PasswordPageComponent({ form }: EnterPasswordProps) {
  const [type, setType] = useState('password');
  const showPassword = () => setType('text');
  const hidePassword = () => setType('password');

  const [state, dispatch] = useLoginAppContextValue();
  const { loginMutation } = useLoginNetworkLayerValue();
  const forgotPasswordStep = goToForgotPassword(dispatch);
  const { getFieldDecorator } = form;
  const {
    userInfo: { firstName },
    email,
  } = state;
  const dispatchLogin = validateAndSubmitLogin({
    form,
    loginMutation,
    email,
  });
  const dispatchGoToEnterEmailPage = goToSelectEnterEmailPage(dispatch);
  const checked = type === 'text';
  return (
    <React.Fragment>
      <s.LoginHeader css>
        <div>Welcome back </div>
        <div>{firstName} 👋</div>
        <div>Password please...</div>
      </s.LoginHeader>
      <Form>
        <Form.Item
          hasFeedback
          extra={
            <div>
              <s.InputCheckShowPassword
                css
                onClick={checked ? hidePassword : showPassword}
              >
                <span>
                  <CheckBoxPrimary checked={checked} /> Show
                </span>
              </s.InputCheckShowPassword>
              <s.InputForgotPasswordLink css onClick={forgotPasswordStep}>
                Forgot Password?
              </s.InputForgotPasswordLink>
            </div>
          }
        >
          {getFieldDecorator(
            'password',
            // @ts-ignore: says that config does not match up with typings
            passwordValidationConfig,
          )(
            // @ts-ignore
            <Input
              autoFocus
              label="Enter Password"
              kind="text"
              type={type}
              name="password"
              autoComplete="current-password"
              onPressEnter={dispatchLogin}
            />,
          )}
        </Form.Item>
      </Form>
      <s.ButtonWrapper css>
        <Button
          block
          type="primary-regular"
          data-cypress="submit-password-button"
          onClick={dispatchLogin}
        >
          Sign In
        </Button>
        <Button type="tertiary-regular" onClick={dispatchGoToEnterEmailPage}>
          Back
        </Button>
      </s.ButtonWrapper>
    </React.Fragment>
  );
}

export default Form.create({
  name: 'password_page',
})(PasswordPageComponent);
