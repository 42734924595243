import { Button } from '@onehope/design-system';
import * as React from 'react';
import { GoogleLogin as ReactGoogleLogin } from 'react-google-login';
import { useLoginNetworkLayerValue } from '../../LoginNetworkLayer';
import { googleConfig } from './LoginMethodButton';
import { GoogleOauthResponse } from './GoogleOauthResponse';

const noop = () => {};

type GoogleLoginProps = {
  responseGoogle: (
    googleResponse: GoogleOauthResponse,
  ) => Promise<void | {
    error: any;
  }>;
};

export default function GoogleLogin({ responseGoogle }: GoogleLoginProps) {
  const { googleClientId } = useLoginNetworkLayerValue();
  return (
    <ReactGoogleLogin
      clientId={googleClientId}
      onSuccess={responseGoogle as any}
      onFailure={noop}
      render={(renderProps?: { onClick: () => void }) => {
        if (renderProps) {
          return (
            <React.Fragment>
              <Button
                block
                type="google"
                data-cypress="login-with-google"
                onClick={renderProps.onClick}
              >
                {googleConfig.text}
              </Button>
            </React.Fragment>
          );
        }
        return <div />;
      }}
    />
  );
}
