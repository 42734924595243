import * as React from 'react';
import styled from '@emotion/styled';
import Form, { FormComponentProps } from 'antd/lib/form';
import { useLoginNetworkLayerValue } from '../../../LoginNetworkLayer';
import isLegalAge from './isLegalAge';
import { useLoginAppContextValue } from '../../../stateManagement/LoginAppContext';
import {
  LoginPageDictionary,
  TypeKeys,
  ActionTypes,
} from '../../../stateManagement/types';
import { RegisterMutationVariables } from '../../../stateManagement/networkMutationTypes';
import { Errors } from '../../../utils/errors';

import {
  submitSsoRegister,
  SubmitRegisterParamsPartial,
} from '../../../utils/validateAndSubmitRegister';

import { styles, Button, InputMask } from '@onehope/design-system';
const { styled: s } = styles;

const maskRegExp = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

type DetermineNextPageAfterAgePage = SubmitRegisterParamsPartial & {
  dispatch: React.Dispatch<ActionTypes>;
  dateOfBirth: string;
};

const FormWrapper = styled(Form)`
  && {
    .has-success.has-feedback .ant-form-item-children-icon,
    .has-warning.has-feedback .ant-form-item-children-icon,
    .has-error.has-feedback .ant-form-item-children-icon {
      margin-top: -10px !important;
    }
  }
`;

function determineNextPage({
  dispatch,
  ssoRegistrationInput,
  loginMutation,
  registerMutation,
  dateOfBirth,
}: DetermineNextPageAfterAgePage) {
  if (ssoRegistrationInput) {
    return submitSsoRegister({
      loginMutation,
      registerMutation,
      ssoRegistrationInput,
      dateOfBirth,
    });
  }
  return dispatch({
    type: TypeKeys.CHANGE_PAGE,
    page: LoginPageDictionary.CREATE_PASSWORD,
  });
}

type SubmitAgeForm = FormComponentProps<{ age: string }>;

type SubmitAgeParams = SubmitRegisterParamsPartial & {
  form: SubmitAgeForm['form'];
  dispatch: React.Dispatch<ActionTypes>;
  ssoRegistrationInput?: RegisterMutationVariables;
};

function submitAge({
  form,
  dispatch,
  ssoRegistrationInput,
  loginMutation,
  registerMutation,
}: SubmitAgeParams) {
  form.validateFieldsAndScroll((err, values) => {
    const { age } = values;
    if (!err && age) {
      dispatch({
        type: TypeKeys.UPDATE_BIRTHDATE,
        birthdate: age,
      });
      return determineNextPage({
        dispatch,
        ssoRegistrationInput,
        loginMutation,
        registerMutation,
        dateOfBirth: age,
      });
    } else if (!age) {
      return form.setFields({
        age: {
          value: form.getFieldValue('age'),
          errors: [new Error('Please select date!')],
        },
      });
    }
    return undefined;
  });
}

function goToSelectLoginMethod(
  dispatch: React.Dispatch<ActionTypes>,
  ssoRegistrationInput?: RegisterMutationVariables,
) {
  return () => {
    if (ssoRegistrationInput) {
      dispatch({
        type: TypeKeys.UPDATE_NAME,
        firstName: '',
        lastName: '',
      });
      dispatch({
        type: TypeKeys.UPDATE_SSO_REGISTRATION_INFO,
        ssoRegistrationInput: undefined,
      });
      dispatch({
        type: TypeKeys.CHANGE_PAGE,
        page: LoginPageDictionary.SELECT_LOGIN_METHOD,
      });
      return;
    }
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.ENTER_NAME,
    });
    return;
  };
}

function confirmAge(
  // @ts-ignore rule never read
  rule: any,
  date: string | null,
  callback: (input?: string) => void,
): void {
  if (date) {
    const isLegal = isLegalAge(date);
    if (typeof isLegal === 'string') {
      callback(isLegal);
    } else if (!isLegal) {
      callback(Errors.LegalAgeLimitToBuyAlcohol);
    }
  }
  callback();
}

const ageValidationConfig = (initialValue?: string | null) => {
  return {
    validateTrigger: ['onBlur'],
    validateFirst: true /*stop validate on first rule of error for this field. */,
    rules: [
      {
        type: 'string',
        required: true,
        message: 'Please select date!',
      },
      {
        pattern: maskRegExp,
        message: 'The input is not a valid date',
      },
      {
        type: 'string',
        validator: confirmAge,
      },
    ],
    initialValue,
  };
};

type AgePageProps = SubmitAgeForm & {};

const AgePageComponent = ({ form }: AgePageProps) => {
  const { getFieldDecorator } = form;
  const { registerMutation, loginMutation } = useLoginNetworkLayerValue();
  const [state, dispatch] = useLoginAppContextValue();
  const [submitting, setSubmitting] = React.useState(false);
  const {
    signUpFlow: { firstName, birthdate },
    ssoRegistrationInput,
  } = state;
  const dispatchVerifyAge = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    await submitAge({
      form,
      dispatch,
      ssoRegistrationInput,
      registerMutation,
      loginMutation,
    });
    setSubmitting(false);
  };
  const initialInput = birthdate || null;
  const dispatchGoToSelectLoginMethod = goToSelectLoginMethod(
    dispatch,
    ssoRegistrationInput,
  );
  return (
    <React.Fragment>
      <s.LoginHeader css>Are You Legal, {`${firstName}`}? 👉🍷</s.LoginHeader>
      <s.LoginText css>
        Given that wine has alcohol in it - surprise! - we need to ensure you're
        21 or older.
      </s.LoginText>
      <s.InputHeader css>Birthday ( MM/DD/YYYY )</s.InputHeader>
      <FormWrapper>
        <Form.Item hasFeedback>
          {getFieldDecorator(
            'age',
            ageValidationConfig(initialInput),
          )(
            <InputMask
              autoFocus
              label="Birthday"
              mask="99/99/9999"
              placeholder="MM/DD/YYYY"
            />,
          )}
        </Form.Item>
        <s.ButtonWrapper css>
          <Button
            block
            type="primary-regular"
            htmlType="submit"
            onClick={dispatchVerifyAge}
          >
            Next
          </Button>
          <Button
            type="tertiary-regular"
            onClick={dispatchGoToSelectLoginMethod}
          >
            Back
          </Button>
        </s.ButtonWrapper>
      </FormWrapper>
    </React.Fragment>
  );
};

export default Form.create({
  name: 'age_page',
})(AgePageComponent);
