import React from 'react';
import { Form, Input, Tooltip, Icon, Button, Spin } from 'antd';
import { Mutation, MutationFn } from 'react-apollo';
import { css } from 'emotion';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import AccountEditMutation from '../../mutations/Account/AccountEdit';
import ShopWithCeoDelete from '../../mutations/Account/ShopWithCeoDelete';

const { TextArea } = Input;

const headerText = css`
  font-weight: bold;
`;

const StopShoppingWithButton = styled.button`
  outline: none;
  margin-left: 160px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: #1890ff;
  :hover {
    cursor: pointer;
  }
`;

type AccountEditMutationVariables = {
  input: {
    email: string;
    firstName: string;
    lastName: string;
    city: string;
    state: string;
    zip: string;
    summary: string;
    oldPassword: string;
    selectedUserId: string;
    mobilePhone?: string;
  };
};

interface AccountInfoProps {
  userInfo: any;
  selectedUserId: string;
}

interface AccountInfoState {
  confirmDirty: boolean;
  isUpdating: boolean;
}

class AccountInfo extends React.Component<AccountInfoProps, AccountInfoState> {
  private timeout: any;
  state = {
    confirmDirty: false,
    isUpdating: false,
  };

  componentWillUnmount(): void {
    clearTimeout(this.timeout);
  }

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  submitAccountUpdate = (
    event: React.ChangeEvent<any>,
    accountEditMutation: MutationFn<any, AccountEditMutationVariables>,
  ) => {
    event.preventDefault();
    this.setState({ isUpdating: true });
    const {
      form,
      selectedUserId,
      userInfo: { password },
    } = this.props;
    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      const {
        email,
        city,
        state,
        zip,
        summary,
        firstName,
        lastName,
        mobilePhone,
      } = values;
      const variables: AccountEditMutationVariables = {
        input: {
          email,
          firstName,
          lastName,
          city,
          state,
          zip,
          summary,
          mobilePhone,
          oldPassword: password,
          selectedUserId,
        },
      };
      return accountEditMutation({ variables });
    });
  };

  cancelShoppingWithCeo = (shopWithCeoDeleteMutation: MutationFn<any, {}>) => {
    const { selectedUserId } = this.props;
    return shopWithCeoDeleteMutation({
      variables: { input: { selectedUserId } },
    });
  };

  accountEditSuccess = (data: any) => {
    this.timeout = setTimeout(() => this.setState({ isUpdating: false }), 1000);
  };

  accountEditFailure = (data: any) => {
    const { form } = this.props;
    this.setState({ isUpdating: false });
    form.setFields({
      email: {
        value: form.getFieldValue('email'),
        errors: [new Error(data.graphQLErrors[0].message)],
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { userInfo } = this.props;
    const uplineInfo = this.props.userInfo?.ce?.mentorInfo;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <React.Fragment>
        <Mutation
          mutation={AccountEditMutation}
          onError={this.accountEditFailure}
          onCompleted={this.accountEditSuccess}
        >
          {(accountEditMutation, { data }) => {
            return (
              <div style={{ maxWidth: 500 }}>
                <div style={{ marginTop: 30, marginLeft: 66 }}>
                  {userInfo.trinityDealerId && (
                    <p>
                      <span className={headerText}>Firestorm ID: </span>{' '}
                      <span> {userInfo.trinityDealerId}</span>
                    </p>
                  )}
                  {userInfo.preferredCustomerCeoUser && (
                    <p>
                      <span className={headerText}>PC SPONSORING CEO: </span>{' '}
                      <span>
                        <Link
                          to={`?user=${userInfo.preferredCustomerCeoUser.userId}`}
                        >
                          {userInfo.preferredCustomerCeoUser.fullName}
                        </Link>
                      </span>
                      <span> {userInfo.preferredCustomerCeoUser.userId}</span>
                    </p>
                  )}
                  {userInfo.shoppingWithCeoUser && (
                    <p>
                      <span className={headerText}>SHOPPING WITH CEO: </span>{' '}
                      <span>
                        <Link
                          to={`?user=${userInfo.shoppingWithCeoUser.userId}`}
                        >
                          {userInfo.shoppingWithCeoUser.fullName}
                        </Link>
                      </span>
                      <span> {userInfo.shoppingWithCeoUser.userId}</span>
                    </p>
                  )}
                  {userInfo.userId && (
                    <p>
                      <span className={headerText}>ONEHOPE ID: </span>{' '}
                      <span> {userInfo.userId}</span>
                    </p>
                  )}
                  {uplineInfo?.fullName && (
                    <p>
                      <span className={headerText}>Upline: </span>
                      <span>{uplineInfo?.fullName}</span>
                    </p>
                  )}
                  {userInfo.userId && (
                    <p>
                      <span className={headerText}>Upline Firestorm ID: </span>{' '}
                      <span> {userInfo.trinityUplineDealerId}</span>
                    </p>
                  )}
                  {uplineInfo?.accountId && (
                    <p>
                      <span className={headerText}>Upline ONEHOPE ID: </span>{' '}
                      <span>
                        <Link to={`?user=${uplineInfo?.accountId}`}>
                          {uplineInfo?.accountId}
                        </Link>
                      </span>
                    </p>
                  )}
                </div>
                <Form
                  layout="horizontal"
                  onSubmit={e =>
                    this.submitAccountUpdate(e, accountEditMutation)
                  }
                >
                  <div style={{ marginLeft: 170, paddingBottom: 20 }} />
                  <Form.Item {...formItemLayout} label="E-mail">
                    {getFieldDecorator('email', {
                      initialValue: userInfo.email,
                      rules: [
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="First Name">
                    {getFieldDecorator('firstName', {
                      initialValue: userInfo.firstName,
                      rules: [
                        {
                          required: true,
                          message: 'Please input First Name!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="Last Name">
                    {getFieldDecorator('lastName', {
                      initialValue: userInfo.lastName,
                      rules: [
                        {
                          required: true,
                          message: 'Please input Last Name!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="Phone">
                    {getFieldDecorator('mobilePhone', {
                      initialValue: userInfo.mobilePhone,
                      rules: [
                        {
                          len: 10,
                          message: `Invalid phone number`,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="City">
                    {getFieldDecorator('city', {
                      initialValue: userInfo.profile.city,
                      rules: [],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="State">
                    {getFieldDecorator('state', {
                      initialValue: userInfo.profile.state,
                      rules: [
                        {
                          len: 2,
                          message: `Invalid state`,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="Zip Code">
                    {getFieldDecorator('zip', {
                      initialValue: userInfo.profile.zip,
                      rules: [
                        {
                          len: 5,
                          message: `Invalid zip`,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <span>
                        Summary&nbsp;
                        <Tooltip title="How do you want to describe yourself to others?">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator('summary', {
                      initialValue: userInfo.profile.summary,
                      rules: [
                        {
                          max: 1000,
                          message: 'Max 1000 characters!',
                        },
                      ],
                    })(<TextArea rows={6} />)}
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                      Update Information
                    </Button>
                    {this.state.isUpdating && (
                      <Spin style={{ marginLeft: 8 }} size="small" />
                    )}
                  </Form.Item>
                </Form>
              </div>
            );
          }}
        </Mutation>
        {userInfo.shoppingWithCeoUser &&
          !userInfo.preferredCustomerCeoAccountId && (
            <Mutation mutation={ShopWithCeoDelete}>
              {(shopWithCeoDeleteMutation: MutationFn<any, {}>) => {
                return (
                  <StopShoppingWithButton
                    onClick={() =>
                      this.cancelShoppingWithCeo(shopWithCeoDeleteMutation)
                    }
                  >
                    Stop Shopping with {userInfo.shoppingWithCeoUser.firstName}
                  </StopShoppingWithButton>
                );
              }}
            </Mutation>
          )}
      </React.Fragment>
    );
  }
}

const WrappedAccountInfo = Form.create({ name: 'account_info' })(AccountInfo);

export default WrappedAccountInfo;
