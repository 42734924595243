import { useFlags } from 'launchdarkly-react-client-sdk';
import { redirectToLoginV2 } from '../../../utils/utils';

const LoginV2Redirect = () => {
  const flags = useFlags();

  if (flags?.loginEnhancements) {
    const redirectUrl = encodeURI(window.location.href);
    redirectToLoginV2(redirectUrl);
  } else {
    window.location.href = '/login';
  }
  return null;
};

export default LoginV2Redirect;
