import * as React from 'react';
import { styles, SplitFullScreen } from '@onehope/design-system';
import LoginNetworkLayer, { DataProps } from './LoginNetworkLayer';
import LoginAppProvider from './stateManagement/LoginAppContext';
import Stepper from './Stepper';

const {
  antDesignStyles: {
    layout: { MainLayout },
  },
} = styles;

const {
  SplitFullScreen: SplitFullScreenComponent,
  presets: { SplitScreenPresets },
} = SplitFullScreen;
type LoginProps = DataProps & {};

export default function Login({
  originUrl,
  facebookAppId,
  googleClientId,
  assetsUrl,
  verifyEmail,
  loginHeader,
  loginText,
  rightImagePreset,
  loginMutation,
  registerMutation,
  sendRecoveryEmailMutation,
  responseGoogle,
  responseFacebook,
  rightComponent,
}: LoginProps) {
  const restOfProps = rightComponent
    ? { ...rightComponent }
    : {
        rightImage: {
          assetsUrl: assetsUrl,
          preset:
            rightImagePreset || SplitScreenPresets.DOING_GOOD_TASTES_GREAT,
        },
      };
  return (
    <MainLayout>
      <SplitFullScreenComponent
        leftChildren={
          <LoginNetworkLayer
            assetsUrl={assetsUrl}
            originUrl={originUrl}
            loginHeader={loginHeader}
            loginText={loginText}
            verifyEmail={verifyEmail}
            facebookAppId={facebookAppId}
            googleClientId={googleClientId}
            loginMutation={loginMutation}
            registerMutation={registerMutation}
            responseGoogle={responseGoogle}
            responseFacebook={responseFacebook}
            sendRecoveryEmailMutation={sendRecoveryEmailMutation}
          >
            <LoginAppProvider>
              <Stepper />
            </LoginAppProvider>
          </LoginNetworkLayer>
        }
        {...restOfProps}
      />
    </MainLayout>
  );
}
