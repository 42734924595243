import { getEmailInputVariables } from '../pages/buttons/loginMutationVariables';
import { LoginMutation } from '../stateManagement/networkMutationTypes';

type ValidateAndSubmitLoginParams = {
  form: any;
  loginMutation: LoginMutation;
  email: string;
};

export function validateAndSubmitLogin({
  form,
  loginMutation,
  email,
}: ValidateAndSubmitLoginParams) {
  return () =>
    form.validateFields((err: any, values: { password: string }) => {
      if (!err) {
        const { password } = values;
        return submitLogin({ password, loginMutation, email })
          .then((res: any) => {
            console.log('res,', res);
            if (res && res.error) {
              form.setFields({
                password: {
                  value: form.getFieldValue('password'),
                  errors: [new Error(res.error)],
                },
              });
            }

            return undefined;
          })
          .catch(err => {
            console.log('err1', err);
          });
      }
      return undefined;
    });
}

export type SubmitLoginParams = {
  password: string;
  loginMutation: LoginMutation;
  email: string;
};

export function submitLogin({
  password,
  loginMutation,
  email,
}: SubmitLoginParams) {
  const inputVariables = getEmailInputVariables(email, password);
  return loginMutation(inputVariables);
}
