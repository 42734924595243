import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import {
  Login,
  GoogleOauthResponse,
  getGoogleInputVariables,
  types,
  getGoogleInputVariablesRegistration,
  FacebookOauthResponse,
  getFacebookInputVariablesRegistration,
  getFacebookInputVariables,
} from '@onehope/login';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';

import {
  suffix,
  verifyEmail,
  loginMutation,
  registerMutation,
  sendRecoveryEmailMutation,
} from '../utils/authentication';
import { LoginMutationVariables } from '../mutations/Authentication/LoginMutation';
import { redirectToLoginV2 } from '../utils/utils';

export type VerifyEmailPayload = {
  verifyEmail: {
    hasFacebook: boolean;
    hasGoogle: boolean;
    isAccount: boolean;
    hasPassword: boolean;
    isCE: boolean;
    isAdmin: boolean;
    firstName: string | null;
  };
  hasError: boolean;
};

const { TypeKeys, LoginPageDictionary } = types;

type AppVariables = {
  loginMutation: (
    variables: LoginMutationVariables,
  ) => Promise<void | {
    error: any;
  }>;
  verifyEmail: (email: string) => Promise<VerifyEmailPayload>;
};

function responseGoogle(
  // App-level concerns
  { verifyEmail, loginMutation }: AppVariables,
) {
  return (
    // Login component-level concerns
    setError: React.Dispatch<React.SetStateAction<string>>,
    dispatch: React.Dispatch<any>,
  ) => {
    return (googleResponse: GoogleOauthResponse) => {
      const email = googleResponse.profileObj.email;
      return verifyEmail(email)
        .then(res => {
          const { isAdmin, isAccount } = res.verifyEmail;
          if (!isAccount) {
            const { givenName, familyName } = googleResponse.profileObj;
            dispatch({
              type: TypeKeys.UPDATE_NAME,
              firstName: givenName,
              lastName: familyName,
            });
            dispatch({
              type: TypeKeys.UPDATE_SSO_REGISTRATION_INFO,
              ssoRegistrationInput: getGoogleInputVariablesRegistration(
                googleResponse,
              ),
            });
            dispatch({
              type: TypeKeys.CHANGE_PAGE,
              page: LoginPageDictionary.ENTER_AGE,
            });
            return;
          }
          if (!isAdmin) {
            return setError('You must have admin permissions to login');
          }
          const inputVariables = getGoogleInputVariables(googleResponse);
          return loginMutation(inputVariables);
        })
        .catch(err => console.log('err', err));
    };
  };
}

function responseFacebook(
  // App-level concerns
  { verifyEmail, loginMutation }: AppVariables,
) {
  return (
    // Login component-level concerns
    setError: React.Dispatch<React.SetStateAction<string>>,
    dispatch: React.Dispatch<any>,
  ) => {
    return (facebookResponse: FacebookOauthResponse) => {
      const { email } = facebookResponse;
      return verifyEmail(email)
        .then(res => {
          const { isAdmin, isAccount } = res.verifyEmail;
          if (!isAccount) {
            const ssoRegistrationInput = getFacebookInputVariablesRegistration(
              facebookResponse,
            );
            const { firstName, lastName } = ssoRegistrationInput.input;
            dispatch({
              type: TypeKeys.UPDATE_SSO_REGISTRATION_INFO,
              ssoRegistrationInput,
            });
            dispatch({
              type: TypeKeys.UPDATE_NAME,
              firstName,
              lastName,
            });
            dispatch({
              type: TypeKeys.CHANGE_PAGE,
              page: LoginPageDictionary.ENTER_AGE,
            });
            return;
          }
          if (!isAdmin) {
            return setError('You must have admin permissions to login');
          }
          const inputVariables = getFacebookInputVariables(facebookResponse);
          return loginMutation(inputVariables);
        })
        .catch(err => console.log('err', err));
    };
  };
}

function LoginPage(props: any) {
  // https://www.gatsbyjs.org/docs/environment-variables/#example
  const { GATSBY_S3_ASSETS } = process.env;
  const { GATSBY_FACEBOOK_APP_ID } = process.env;
  const { GATSBY_GOOGLE_CLIENT_ID } = process.env;

  const flags = useFlags();

  if (flags?.loginEnhancements) {
    const redirectUrl = encodeURI(props.location.href);
    return redirectToLoginV2(redirectUrl);
  }

  if (!GATSBY_S3_ASSETS) return <div>We can't pull images, {suffix}</div>;
  if (!GATSBY_FACEBOOK_APP_ID)
    return <div>We can't login using Facebook, {suffix}</div>;
  if (!GATSBY_GOOGLE_CLIENT_ID)
    return <div>We can't login using Google, {suffix}</div>;
  const redirectUri =
    new URLSearchParams(props.location.search).get('redirect_uri') || '/';
  const originUrl = props.location.origin;

  return (
    <ApolloConsumer>
      {client => {
        const login = loginMutation(client, redirectUri);
        const verify = verifyEmail(client);
        return (
          <Login
            originUrl={originUrl}
            assetsUrl={GATSBY_S3_ASSETS}
            facebookAppId={GATSBY_FACEBOOK_APP_ID}
            googleClientId={GATSBY_GOOGLE_CLIENT_ID}
            verifyEmail={verify}
            loginMutation={login}
            sendRecoveryEmailMutation={sendRecoveryEmailMutation(client)}
            registerMutation={registerMutation(client)}
            responseGoogle={responseGoogle({
              verifyEmail: verify,
              loginMutation: login,
            })}
            responseFacebook={responseFacebook({
              verifyEmail: verify,
              loginMutation: login,
            })}
          />
        );
      }}
    </ApolloConsumer>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(LoginPage);
