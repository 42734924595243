import { StateType } from '../../stateManagement/types';
import { RegisterMutationVariables } from '../../stateManagement/networkMutationTypes';

export function getRegisterInputVariables(
  state: StateType,
  password: string
): RegisterMutationVariables {
  const { signUpFlow, email } = state;
  const { firstName = '', lastName = '', birthdate } = signUpFlow;
  return {
    input: {
      id: '',
      firstName,
      lastName,
      email,
      password,
      dateOfBirth: birthdate,
    },
  };
}
