import * as React from 'react';
// @ts-ignore: Wants ts-typings for package
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Button } from '@onehope/design-system';

import { useLoginNetworkLayerValue } from '../../LoginNetworkLayer';
import { facebookConfig } from './LoginMethodButton';
import { FacebookOauthResponse } from './FacebookOauthResponse';

type FacebookLoginProps = {
  responseFacebook: (
    facebookResponse: FacebookOauthResponse,
  ) => Promise<void | {
    error: any;
  }>;
};

export default function FacebookLogin({
  responseFacebook,
}: FacebookLoginProps) {
  const { facebookAppId } = useLoginNetworkLayerValue();
  return (
    <ReactFacebookLogin
      version="3.1"
      autoLoad={false}
      fields="email,name,picture"
      disableMobileRedirect
      appId={facebookAppId}
      callback={responseFacebook}
      render={(renderProps: any) => (
        <Button
          block
          type="facebook"
          data-cypress="login-with-facebook"
          onClick={renderProps.onClick}
        >
          {facebookConfig.text}
        </Button>
      )}
    />
  );
}
