import React, { Component } from 'react';
import { Card, Button, Popconfirm, Spin } from 'antd';
import moment from 'moment';
import { css } from 'emotion';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';
import { subscriptionPlanIdsConstants } from './subscriptionProductConstantsJS';
import OrderCancelMutation from '../../../mutations/Orders/OrderCancelMutation';
import SubscriptionCancelMutation from '../../../mutations/Subscriptions/SubscriptionCancelMutation';
import WineClubCancelImmediateMutation from '../../../mutations/Subscriptions/WineClubCancelImmediateMutation';

import { Mutation } from 'react-apollo';

interface SubscriptionCardProps {
  subscription: any;
  selectedUserId: string;
  isPreferredCustomer: boolean;
  isCE: boolean;
  hidePCCancelButtons: boolean;
}

const headerText = css`
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  margin-top: 50px;
`;

interface SubscriptionCardState {
  isCancelling: boolean;
  disableRenewButton: boolean;
  disableRefundButton: boolean;
}

class SubscriptionCard extends Component<
  SubscriptionCardProps,
  SubscriptionCardState
> {
  constructor(props: SubscriptionCardProps) {
    super(props);
    this.state = {
      isCancelling: false,
      disableRenewButton: this.checkInvalidSubscriptionStatus(),
      disableRefundButton: this.checkShouldDisableRefund(),
    };
  }

  checkShouldDisableRefund = () => {
    return this.checkInvalidSubscriptionStatus() ||
      this.checkInvalidOrderStatus()
      ? true
      : false;
  };

  checkInvalidSubscriptionStatus = () => {
    const { subscription } = this.props;
    const { enrollmentStatus } = subscription;
    if (enrollmentStatus === 'cancelled') return true;
    return false;
  };

  checkInvalidOrderStatus = () => {
    const { subscription } = this.props;
    const { orderStatus } = subscription;
    if (!orderStatus) return false;
    switch (orderStatus) {
      case 'Payment Pending':
      case 'Complete':
      case 'Order Received':
      case 'Sent to ShipCompliant':
        return false;
      case null:
        return false;
      default:
        return true;
    }
  };

  onMutationError = () => {
    return this.setState({ isCancelling: false });
  };

  onRenewMutationCompleted = () => {
    return this.setState({ isCancelling: false, disableRenewButton: true });
  };

  onRefundMutationCompleted = () => {
    return this.setState({ isCancelling: false, disableRefundButton: true });
  };

  wineClubCancelImmediately = (
    wineClubCancelImmediately: (input: object) => void,
  ) => {
    if (this.state.isCancelling) return null;
    this.setState({ isCancelling: true });
    const { selectedUserId } = this.props;
    return wineClubCancelImmediately({
      variables: {
        input: {
          accountId: selectedUserId,
        },
      },
    });
  };

  subscriptionCancel = (subscriptionCancel: (input: object) => void) => {
    if (this.state.isCancelling) return null;
    this.setState({ isCancelling: true });
    const { selectedUserId } = this.props;
    return subscriptionCancel({
      variables: {
        input: {
          accountId: selectedUserId,
        },
      },
    });
  };

  orderCancel = (orderCancel: (input: object) => void) => {
    if (this.state.isCancelling) return null;
    this.setState({ isCancelling: true });
    const { subscription } = this.props;
    const { orderId, totalNumberOrderItems } = subscription;
    let cancelOptions;
    if (totalNumberOrderItems && totalNumberOrderItems === 1) {
      cancelOptions = {
        skipShipCompliant: true,
        refundPreferredCustomerMembership: true,
        cancelPreferredCustomerMembership: true,
      };
    } else {
      cancelOptions = {
        refundPreferredCustomerMembership: true,
        cancelPreferredCustomerMembership: true,
      };
    }
    return orderCancel({
      variables: {
        input: {
          orderId,
          cancelOptions,
        },
      },
    });
  };

  formatDate = (date: string) => {
    return moment
      .utc(date)
      .local()
      .format('ll');
  };

  getNextBillAmount = () => {
    const { subscription } = this.props;
    const {
      CEPlanId,
      CEMonthlyPlanId,
      CEMonthly2PlanId,
    } = subscriptionPlanIdsConstants;
    if (subscription.planId === CEPlanId) {
      return '$99';
    } else if (subscription.planId === CEMonthlyPlanId) {
      return '$9.99';
    } else if (subscription.planId === CEMonthly2PlanId) {
      return '$12';
    }
    return '';
  };

  getOldestDate = () => {
    const { subscription } = this.props;
    const { CECreationDate, trinityEnrollDate } = subscription;
    return new Date(
      DateTime.min(
        DateTime.fromISO(CECreationDate),
        DateTime.fromISO(trinityEnrollDate),
      ),
    ).toISOString();
  };

  render() {
    const {
      subscription,
      isPreferredCustomer,
      isCE,
      hidePCCancelButtons,
    } = this.props;
    const popUpTitle =
      subscription &&
      subscription.totalNumberOrderItems &&
      subscription.totalNumberOrderItems > 1
        ? 'PC Membership order has other items – are you sure you want to cancel? This will cancel the entire order and refund any shipping costs.'
        : 'Cancel PC Membership and Refund Fee?';
    return (
      <div style={{ marginTop: 30 }}>
        {subscription.subscriptionId && (
          <p>
            <span className={headerText}>Subscription ID: </span>{' '}
            <span>{subscription.subscriptionId}</span>
          </p>
        )}
        {subscription.grapefulCreationDate && (
          <p>
            <span className={headerText}>Member Since: </span>{' '}
            <span>{this.formatDate(subscription.grapefulCreationDate)}</span>
          </p>
        )}
        {subscription.marisCreationDate && (
          <p>
            <span className={headerText}>Member Since: </span>{' '}
            <span>{this.formatDate(subscription.marisCreationDate)}</span>
          </p>
        )}
        {subscription.CECreationDate && subscription.trinityEnrollDate && (
          <p>
            <span className={headerText}>Member Since: </span>{' '}
            <span>{this.formatDate(this.getOldestDate())}</span>
          </p>
        )}
        {subscription.enrollmentStatus && (
          <p>
            <span className={headerText}>Enrollment Status: </span>{' '}
            <span>{subscription.enrollmentStatus}</span>
          </p>
        )}
        {subscription.subscriptionStatus && (
          <p>
            <span className={headerText}>Subscription Status: </span>{' '}
            <span>{subscription.subscriptionStatus}</span>
          </p>
        )}
        {subscription.grapefulRenewalDate && (
          <p>
            <span className={headerText}>Next Bill Date: </span>{' '}
            <span>{this.formatDate(subscription.grapefulRenewalDate)}</span>
          </p>
        )}
        {subscription.marisRenewalDate && (
          <p>
            <span className={headerText}>Next Bill Date: </span>{' '}
            <span>{this.formatDate(subscription.marisRenewalDate)}</span>
          </p>
        )}
        {subscription.CERenewalDate && (
          <p>
            <span className={headerText}>Next Bill Date: </span>{' '}
            <span>{this.formatDate(subscription.CERenewalDate)}</span>
          </p>
        )}
        {subscription.planId && (
          <p>
            <span className={headerText}>Next Bill Amount: </span>{' '}
            <span>{this.getNextBillAmount()}</span>
          </p>
        )}
        {subscription.paymentStatus && (
          <p>
            <span className={headerText}>Payment Status: </span>{' '}
            <span>{subscription.paymentStatus}</span>
          </p>
        )}
        {subscription.paymentInfo && subscription.paymentInfo.expMonth && (
          <Card size="small" title="Payment Info" style={{ width: 500 }}>
            <p>
              {`${subscription.paymentInfo.nickname || ''} ( ${
                subscription.paymentInfo.brand
              } card ending in *${subscription.paymentInfo.last4} exp: ${
                subscription.paymentInfo.expMonth
              }/${subscription.paymentInfo.expYear} )`}
            </p>
          </Card>
        )}
        {subscription.shippingAddress &&
          subscription.shippingAddress.addressLineOne && (
            <Card
              size="small"
              title="Shipping Address"
              style={{ marginTop: 20, width: 500 }}
            >
              <div>{`${subscription.shippingAddress.companyName}`}</div>
              <div>
                <div>{`${subscription.shippingAddress.firstName} ${subscription.shippingAddress.lastName}`}</div>
              </div>
              <div>{`${subscription.shippingAddress.addressLineOne}`}</div>
              <div>{`${subscription.shippingAddress.addressLineTwo}`}</div>
              <div>{`${subscription.shippingAddress.city}, ${subscription.shippingAddress.state} ${subscription.shippingAddress.zip}`}</div>
            </Card>
          )}
        {/* puts customer in a state where they have PC benefits 
          (up until the expiration date) but will not be re-enrolled */}
        {isPreferredCustomer &&
          !hidePCCancelButtons &&
          subscription.subscriptionStatus && (
            <ButtonContainer>
              <Mutation
                mutation={SubscriptionCancelMutation}
                onError={this.onMutationError}
                onCompleted={this.onRenewMutationCompleted}
              >
                {(subscriptionCancel: any, { data }) => (
                  <div>
                    <Popconfirm
                      title="Do Not Renew PC Membership?"
                      cancelText="No"
                      okText="Yes"
                      onConfirm={() =>
                        this.subscriptionCancel(subscriptionCancel)
                      }
                    >
                      <Button
                        style={{ marginTop: 20 }}
                        htmlType="button"
                        type="danger"
                        title="Cancel"
                        size="default"
                        ghost={true}
                        disabled={this.state.disableRenewButton}
                      >
                        Do Not Renew PC Membership
                      </Button>
                      {this.state.isCancelling && (
                        <Spin style={{ marginLeft: 8 }} size="small" />
                      )}
                    </Popconfirm>
                  </div>
                )}
              </Mutation>
              {/* cancel subscription immediately and only refund membership fee */}
              <Mutation
                mutation={OrderCancelMutation}
                onError={this.onMutationError}
                onCompleted={this.onRefundMutationCompleted}
              >
                {(orderCancel: any, { data }) => (
                  <div>
                    <Popconfirm
                      title={popUpTitle}
                      cancelText="No"
                      okText="Yes"
                      onConfirm={() => this.orderCancel(orderCancel)}
                    >
                      <Button
                        style={{ marginTop: 20 }}
                        htmlType="button"
                        type="danger"
                        title="Cancel"
                        size="default"
                        ghost={true}
                        disabled={this.state.disableRefundButton}
                      >
                        Cancel PC Membership and Refund Membership Fee
                      </Button>
                      {this.state.isCancelling && (
                        <Spin style={{ marginLeft: 8 }} size="small" />
                      )}
                    </Popconfirm>
                  </div>
                )}
              </Mutation>
              {this.checkInvalidOrderStatus() && (
                <div>
                  Cannot refund PC Membership order because order status is "
                  {subscription.orderStatus}."
                </div>
              )}
            </ButtonContainer>
          )}{' '}
        {!isPreferredCustomer && !isCE && subscription.subscriptionStatus && (
          <ButtonContainer>
            <Mutation
              mutation={WineClubCancelImmediateMutation}
              onError={this.onMutationError}
              onCompleted={this.onRenewMutationCompleted}
            >
              {(wineClubCancelImmediately: any, { data }) => (
                <div>
                  <Popconfirm
                    title="Cancel Mari’s Quarterly picks?"
                    cancelText="No"
                    okText="Yes"
                    onConfirm={() =>
                      this.wineClubCancelImmediately(wineClubCancelImmediately)
                    }
                  >
                    <Button
                      style={{ marginTop: 20 }}
                      htmlType="button"
                      type="danger"
                      title="Cancel"
                      size="default"
                      ghost={true}
                      disabled={this.state.disableRenewButton}
                    >
                      Cancel Mari's Picks Membership
                    </Button>
                    {this.state.isCancelling && (
                      <Spin style={{ marginLeft: 8 }} size="small" />
                    )}
                  </Popconfirm>
                </div>
              )}
            </Mutation>
          </ButtonContainer>
        )}
      </div>
    );
  }
}

export default SubscriptionCard;
