// Following is taken from https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
import * as React from 'react';
import reducer from './reducer';
// import asyncMiddleware from './asyncMiddleware';
import { LoginPageDictionary, StateType, ActionTypes } from './types';

const { createContext, useReducer, useContext } = React;

const initialState: StateType = {
  page: LoginPageDictionary.SELECT_LOGIN_METHOD,
  signUpFlow: {},
  email: '',
  userInfo: {},
};

export type LoginAppReducer = [StateType, React.Dispatch<ActionTypes>];

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const LoginAppContext = createContext<LoginAppReducer>();

export default function LoginAppProvider({ children }: any) {
  const sync = useReducer(reducer, initialState);
  // const [state, dispatch] = sync;
  // const async = [state, asyncMiddleware(dispatch)];
  return (
    <LoginAppContext.Provider value={sync}>{children}</LoginAppContext.Provider>
  );
}

export const useLoginAppContextValue = () =>
  useContext<LoginAppReducer>(LoginAppContext);
