// @ts-ignore
import * as React from 'react';
import { styles } from '@onehope/design-system';
const { styled: s } = styles;

export default function CheckYourEmailPage() {
  return (
    <React.Fragment>
      <s.LoginHeader css>
        Check your email to reset your password 💌
      </s.LoginHeader>
      <s.LoginText css>
        A link to reset your password has been sent to your email.
      </s.LoginText>
    </React.Fragment>
  );
}
