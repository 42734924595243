import * as React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import Spin from 'antd/lib/spin';
import 'antd/lib/spin/style/index.css';
import { useLoginAppContextValue } from '../../stateManagement/LoginAppContext';

import {
  LoginPageDictionary,
  TypeKeys,
  ActionTypes,
} from '../../stateManagement/types';
import {
  VerifyEmail,
  VerifyEmailPayload,
} from '../../stateManagement/networkMutationTypes';
import { useLoginNetworkLayerValue } from '../../LoginNetworkLayer';
import { Errors } from '../../utils/errors';

import { styles, Button, Input } from '@onehope/design-system';
const { styled: s } = styles;
const { useState } = React;

type EmailForm = FormComponentProps<{ email: string }>;

type SubmitEmailParams = {
  form: EmailForm['form'];
  dispatch: React.Dispatch<ActionTypes>;
  verifyEmail: VerifyEmail;
  setIsLoading: (input: boolean) => void;
};

function determineNextPage(
  dispatch: React.Dispatch<ActionTypes>,
  verifyEmailResult: VerifyEmailPayload['verifyEmail'],
) {
  const { hasFacebook, hasGoogle, isAccount, hasPassword } = verifyEmailResult;
  if (isAccount) {
    if (hasPassword && !hasGoogle && !hasFacebook) {
      return dispatch({
        type: TypeKeys.CHANGE_PAGE,
        page: LoginPageDictionary.ENTER_PASSWORD,
      });
    }
    if (hasGoogle || hasFacebook) {
      return dispatch({
        type: TypeKeys.CHANGE_PAGE,
        page: LoginPageDictionary.CREATED_WITH_SSO,
      });
    }
    if (!hasPassword && !hasGoogle && !hasFacebook) {
      return dispatch({
        type: TypeKeys.CHANGE_PAGE,
        page: LoginPageDictionary.FORGOT_PASSWORD,
      });
    }
  }

  // Not an account, ask to register
  return dispatch({
    type: TypeKeys.CHANGE_PAGE,
    page: LoginPageDictionary.ENTER_NAME,
  });
}

function submitEmail({
  form,
  verifyEmail,
  dispatch,
  setIsLoading,
}: SubmitEmailParams) {
  return () =>
    form.validateFields((err: any, values: { email: string }) => {
      if (!err) {
        setIsLoading(true);
        const { email } = values;
        return verifyEmail(email, form).then(
          ({ verifyEmail: verifyEmailPayload, hasError }) => {
            setIsLoading(false);
            const { isAccount } = verifyEmailPayload;
            if (hasError) return;
            dispatch({
              type: TypeKeys.UPDATE_USER_INFO,
              ...verifyEmailPayload,
              firstName: verifyEmailPayload.firstName || '',
              email,
            });
            return determineNextPage(dispatch, verifyEmailPayload);
          },
        );
      }
      return undefined;
    });
}

type EmailPageProps = EmailForm & {};

function goToSelectLoginMethod(dispatch: any) {
  return () => {
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.SELECT_LOGIN_METHOD,
    });
  };
}

const emailValidationConfig = (initialValue: string) => {
  return {
    validateTrigger: 'onBlur',
    validateFirst: true /*stop validate on first rule of error for this field. */,
    rules: [
      {
        type: 'email',
        message: 'The email you entered is not valid.',
      },
      {
        required: true,
        message: 'Please input your email',
      },
    ],
    initialValue,
  };
};

const EmailPageComponent = ({ form }: EmailPageProps) => {
  const [isLoading, setIsLoading] = useState(false);

  // @ts-ignore
  const { getFieldProps } = form;
  const [{ email }, dispatch] = useLoginAppContextValue();
  const { verifyEmail } = useLoginNetworkLayerValue();

  const dispatchVerifyEmail = submitEmail({
    form,
    verifyEmail,
    dispatch,
    setIsLoading,
  });
  const emailProps = getFieldProps('email', emailValidationConfig(email));
  const dispatchGoToSelectLoginMethod = goToSelectLoginMethod(dispatch);
  return (
    <React.Fragment>
      <s.LoginHeader css>What's your email?</s.LoginHeader>
      <Form>
        <Form.Item hasFeedback>
          <Input
            label="Email address"
            kind="text"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
            {...emailProps}
          />
        </Form.Item>
        <s.ButtonWrapper css>
          <Button
            block
            type="primary-regular"
            htmlType="submit"
            data-cypress="submit-email-button"
            onClick={dispatchVerifyEmail}
          >
            {isLoading ? <Spin /> : 'Next'}
          </Button>
          <Button
            type="tertiary-regular"
            onClick={dispatchGoToSelectLoginMethod}
          >
            Back
          </Button>
        </s.ButtonWrapper>
      </Form>
    </React.Fragment>
  );
};

export default Form.create({
  name: 'email_page',
})(EmailPageComponent);
