import * as React from 'react';
import { TextareaAutosizeProps } from '@material-ui/core/TextareaAutosize';
import { css } from 'emotion';
import MuiToolTip from '../ToolTip';
import {
  MuiTextArea,
  Wrap,
  ResizeIcon,
  textAreaBaseStyle,
  validStyle,
  LabelContainer,
  Label,
  WordCount,
} from './input.styles';

type InputAutosizeProps = TextareaAutosizeProps &
  Partial<{
    label?: string;
    validInput?: boolean;
    maxCount?: string;
    toolTipText?: string;
    name: string;
    value: string;
  }>;

export default function InputAutosizeContainer(props: InputAutosizeProps) {
  const { label, validInput, maxCount, toolTipText, ...rest } = props;
  return (
    <React.Fragment>
      <Wrap validInput={validInput}>
        <ResizeIcon />
        {label && (
          <LabelContainer>
            <Label htmlFor={props.name}>{label}</Label>
            {toolTipText && (
              <MuiToolTip
                title={toolTipText}
                arrow
                placement="top"
                enterDelay={500}
                leaveDelay={200}
                style={{ fontSize: 14 }}
              >
                {}
              </MuiToolTip>
            )}
          </LabelContainer>
        )}
        <MuiTextArea
          className={css`
            ${textAreaBaseStyle};
            ${validInput && validStyle};
          `}
          {...rest}
        />
      </Wrap>
      {maxCount && typeof props.value === 'string' && (
        <WordCount>
          {(props.value || '').length}/{maxCount}
        </WordCount>
      )}
    </React.Fragment>
  );
}
