import gql from 'graphql-tag';

const mutation = gql`
  mutation OrderCancelMutation($input: OrderCancelInput!) {
    orderCancel(input: $input) {
      order {
        id
        orderStatus
      }
    }
  }
`;

export default mutation;
