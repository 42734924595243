// @ts-ignore
import * as React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { useLoginAppContextValue } from '../../stateManagement/LoginAppContext';
import {
  LoginPageDictionary,
  TypeKeys,
  ActionTypes,
} from '../../stateManagement/types';
import {
  VerifyEmail,
  SendRecoveryEmailMutation,
} from '../../stateManagement/networkMutationTypes';
import { useLoginNetworkLayerValue } from '../../LoginNetworkLayer';

import { styles, Button, Input } from '@onehope/design-system';
const { styled: s } = styles;

type EmailForm = FormComponentProps<{ email: string }>;

type SubmitEmailParams = {
  form: EmailForm['form'];
  originUrl: string;
  dispatch: React.Dispatch<ActionTypes>;
  verifyEmail: VerifyEmail;
  sendRecoveryEmailMutation: SendRecoveryEmailMutation;
};

type SendEmailRecoveryParams = {
  email: string;
  dispatch: React.Dispatch<ActionTypes>;
  originUrl: string;
  sendRecoveryEmailMutation: SendRecoveryEmailMutation;
};

function determineNextPage(dispatch: any) {
  return dispatch({
    type: TypeKeys.CHANGE_PAGE,
    page: LoginPageDictionary.CHECK_YOUR_EMAIL,
  });
}

function sendRecoveryEmail({
  sendRecoveryEmailMutation,
  email,
  originUrl,
  dispatch,
}: SendEmailRecoveryParams) {
  return sendRecoveryEmailMutation({ input: { email, originUrl } })
    .then((res: any) => {
      console.log('recovery email sent,', res);
      return determineNextPage(dispatch);
    })
    .catch((err: any) => {
      console.log('err1', err);
    });
}

function submitEmail({
  form,
  originUrl,
  verifyEmail,
  dispatch,
  sendRecoveryEmailMutation,
}: SubmitEmailParams) {
  return () =>
    form.validateFields((err: any, values: { email: string }) => {
      if (!err) {
        const { email } = values;
        return verifyEmail(email, form).then(
          ({ verifyEmail: verifyEmailResult }) => {
            if (verifyEmailResult && !verifyEmailResult.isAccount) {
              return form.setFields({
                email: {
                  value: form.getFieldValue('email'),
                  errors: [
                    new Error(
                      'Please check spelling, we do not recognize that address.',
                    ),
                  ],
                },
              });
            }
            dispatch({
              type: TypeKeys.UPDATE_USER_INFO,
              ...verifyEmailResult,
              firstName: verifyEmailResult.firstName || '',
              email,
            });
            return sendRecoveryEmail({
              sendRecoveryEmailMutation,
              email,
              originUrl,
              dispatch,
            });
          },
        );
      }
      return undefined;
    });
}

type ForgotYourPasswordPageProps = FormComponentProps & {};

function goToSelectBackStep(
  dispatch: any,
  isSSO?: boolean,
  isAccountWithoutPassword?: boolean,
) {
  if (isAccountWithoutPassword) {
    return () => {
      dispatch({
        type: TypeKeys.CHANGE_PAGE,
        page: LoginPageDictionary.ENTER_EMAIL,
      });
    };
  }
  if (isSSO) {
    return () => {
      dispatch({
        type: TypeKeys.CHANGE_PAGE,
        page: LoginPageDictionary.CREATED_WITH_SSO,
      });
    };
  }
  return () => {
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.ENTER_PASSWORD,
    });
  };
}

const emailValidationConfig = (initialValue: string) => {
  return {
    validateTrigger: 'onBlur',
    rules: [
      {
        type: 'email',
        message: 'The input is not a valid email',
      },
      { required: true, message: 'Please input your email' },
    ],
    initialValue,
  };
};

const ForgotYourPasswordPage = ({ form }: ForgotYourPasswordPageProps) => {
  const { getFieldDecorator } = form;
  const [
    {
      email,
      userInfo: { hasFacebook, hasGoogle, hasPassword, isAccount },
    },
    dispatch,
  ] = useLoginAppContextValue();
  const isSSO = hasFacebook || hasGoogle;
  const isAccountWithoutPassword =
    isAccount && !hasFacebook && !hasGoogle && !hasPassword;

  const {
    originUrl,
    verifyEmail,
    sendRecoveryEmailMutation,
  } = useLoginNetworkLayerValue();
  const dispatchSendRecoveryEmail = submitEmail({
    form,
    originUrl,
    verifyEmail,
    dispatch,
    sendRecoveryEmailMutation,
  });

  const dispatchGoToSelectBackStep = goToSelectBackStep(
    dispatch,
    isSSO,
    isAccountWithoutPassword,
  );

  const header = isAccountWithoutPassword
    ? 'Whoops! Your account needs a password 🤔'
    : 'Forgot your password? 🤔';
  const summary = isAccountWithoutPassword
    ? 'Enter the email address associated with your account, and we’ll email you a link so that you can create a new password!'
    : 'Enter the email address associated with your account, and we’ll email you a link to reset your password.';
  return (
    <React.Fragment>
      <s.LoginHeader css>{header}</s.LoginHeader>
      <s.LoginText css>{summary}</s.LoginText>
      <Form>
        <Form.Item hasFeedback>
          {getFieldDecorator(
            'email',
            // @ts-ignore: says that config does not match up with typings
            emailValidationConfig(email),
          )(
            // @ts-ignore
            <Input
              autoFocus
              label="Email address"
              kind="text"
              type="email"
              name="email"
              autoComplete="email"
            />,
          )}
        </Form.Item>
      </Form>
      <s.ButtonWrapper css>
        <Button
          block
          type="primary-regular"
          onClick={dispatchSendRecoveryEmail}
        >
          Submit
        </Button>
        <Button type="tertiary-regular" onClick={dispatchGoToSelectBackStep}>
          Back
        </Button>
      </s.ButtonWrapper>
    </React.Fragment>
  );
};

export default Form.create({
  name: 'forgot_password_page',
})(ForgotYourPasswordPage);
