import gql from 'graphql-tag';

const mutation = gql`
  mutation EventEditMutation($input: EventEditInput!) {
    eventEdit(input: $input) {
      eventId
      entryEdge {
        cursor
        node {
          id
          eventId
          donationId
          eventId
          eventDate
          closeDate
          hostFirstName
          hostLastName
          trinityPartyStatus
          trinityPartyId
          hostKitOrderNumbers
          hostKitQuantity
          donationInfo {
            id
            ordersSubTotal
            donationAmount
            donationType
            status
            lobMemo
          }
        }
      }
      event {
        id
        eventId
        donationId
        eventId
        eventDate
        closeDate
        hostFirstName
        hostLastName
        trinityPartyStatus
        trinityPartyId
        hostKitOrderNumbers
        hostKitQuantity
        donationInfo {
          id
          ordersSubTotal
          donationAmount
          donationType
          status
          lobMemo
        }
      }
    }
  }
`;

export default mutation;
