import gql from 'graphql-tag';

const GET_ACCOUNT_INFO_QUERY = gql`
  query ADash_AccountInfoQuery($accountId: String, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        id
        role
        userId
        firstName
        lastName
        email
        role
        mobilePhone
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        password
        hasSSOAccount
        hasGoogleLogin
        hasFacebookLogin
        preferredCustomerCeoAccountId
        shoppingWithCeoUser {
          id
          userId
          firstName
          fullName
        }
        preferredCustomerCeoUser {
          id
          userId
          fullName
        }
        shippingAddressIdDefault
        profile {
          id
          image
          city
          state
          zip
          summary
        }
        shippingAddressDefault {
          id
          lastName
          firstName
          street
          addressLineOne
          addressLineTwo
          city
          state
          addressId
          zip
        }
        paymentDefault {
          id
          name
          expMonth
          expYear
          last4
          paymentId
        }
        trinityDealerId
        trinityUplineDealerId
        ce {
          mentorInfo {
            fullName
            accountId
          }
        }
      }
    }
  }
`;

export default GET_ACCOUNT_INFO_QUERY;
