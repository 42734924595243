import React, { Component } from 'react';
import { Tabs } from 'antd';
import Card from './Card';
import AutoshipCard, { AutoshipCardProps } from '../AutoshipCards/AutoshipCard';

const TabPane = Tabs.TabPane;

interface SubscriptionCardsProps {
  subscriptions: any;
  selectedUserId: string;
}

class SubscriptionCards extends Component<SubscriptionCardsProps, {}> {
  render() {
    const { subscriptions, selectedUserId, autoShips } = this.props;

    const hasPCMembership =
      subscriptions &&
      subscriptions.preferredCustomer &&
      subscriptions.preferredCustomer.subscriptionId;
    const hasCEMembership =
      subscriptions && subscriptions.CE && subscriptions.CE.subscriptionId;
    const hidePCCancelButtons =
      subscriptions &&
      subscriptions.preferredCustomer &&
      subscriptions.preferredCustomer.paymentStatus === 'no payment required'
        ? true
        : false;

    const autoShipTabs = autoShips
      .sort(
        (firstEl: AutoshipCardProps, secondEl: AutoshipCardProps) =>
          firstEl.autoShipId - secondEl.autoShipId,
      )
      .map((autoshipProps: AutoshipCardProps) => (
        <TabPane
          tab={autoshipProps.autoShipName}
          key={`autoship-${autoshipProps.autoShipId}`}
        >
          <AutoshipCard {...autoshipProps} />
        </TabPane>
      ));

    return (
      <>
        <div style={{ marginTop: 30 }}>
          <Tabs type="card" defaultActiveKey="1" tabPosition="top">
            {hasPCMembership && (
              <TabPane tab="Preferred Customer Membership" key="1">
                <Card
                  isPreferredCustomer
                  isCE={false}
                  hidePCCancelButtons={hidePCCancelButtons}
                  subscription={subscriptions.preferredCustomer}
                  selectedUserId={selectedUserId}
                />
              </TabPane>
            )}
            {hasCEMembership && (
              <TabPane tab="CE Membership" key="3">
                <Card
                  isPreferredCustomer={false}
                  isCE
                  hidePCCancelButtons={hidePCCancelButtons}
                  subscription={subscriptions.CE}
                  selectedUserId={selectedUserId}
                />
              </TabPane>
            )}
            {autoShipTabs}
          </Tabs>
        </div>
      </>
    );
  }
}

export default SubscriptionCards;
