import gql from 'graphql-tag';

const PREFERRED_CUSTOMER_CANCEL_MUTATION = gql`
  mutation SubscriptionCancelMutation($input: SubscriptionCancelInput!) {
    subscriptionCancel(input: $input) {
      clientMutationId
      user {
        id
        email
        subscriptions {
          id
          preferredCustomer {
            id
            grapefulCreationDate
            savings
            subscriptionId
            enrollmentStatus
            grapefulExpirationDate
            grapefulRenewalDate
            enrollmentCancelDate
          }
          wineClub {
            id
            marisCreationDate
            marisRenewalDate
            savings
            nextPaymentDate
            enrollmentStatus
            subscriptionStatus
            subscriptionCancelDate
            shippingAddress {
              id
              addressId
              addressLineOne
              addressLineTwo
              city
              companyName
              country
              firstName
              id
              lastName
              specialInstructions
              phoneNumber
              recipientBirthDate
              state
              zip
              nickname
            }
            paymentInfo {
              id
              last4
            }
            subscriptionId
          }
        }
      }
    }
  }
`;

export default PREFERRED_CUSTOMER_CANCEL_MUTATION;
