import { RegisterMutationVariables } from '../../stateManagement/networkMutationTypes';
import { FacebookOauthResponse } from './FacebookOauthResponse';
import { GoogleOauthResponse } from './GoogleOauthResponse';

export function getGoogleInputVariablesRegistration(
  response: GoogleOauthResponse
): RegisterMutationVariables {
  const { email, givenName, familyName, imageUrl } = response.profileObj;
  return {
    input: {
      id: '',
      email,
      facebookToken: false,
      googleToken: true,
      password: response.tokenId,
      firstName: givenName,
      lastName: familyName || 'Hope',
      imageUrl,
    },
  };
}

function getFirstAndLastName(
  str: string
): { firstName: string; lastName: string } {
  return {
    firstName: str.substr(0, str.indexOf(' ')),
    lastName: str.substr(str.indexOf(' ') + 1),
  };
}

export function getFacebookInputVariablesRegistration(
  response: FacebookOauthResponse
): RegisterMutationVariables {
  const { email, accessToken, picture, name } = response;
  const { firstName, lastName } = getFirstAndLastName(name);
  return {
    input: {
      id: '',
      email,
      facebookToken: true,
      googleToken: false,
      password: accessToken,
      firstName,
      lastName,
      imageUrl: picture.data.url,
    },
  };
}
