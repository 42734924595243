import { DateTime, Interval } from 'luxon';

export default function isLegalAge(date: string): boolean | string {
  const dateFormat = date.replace(/\//g, '-');
  const formattedDate = DateTime.fromFormat(dateFormat, 'MM-dd-yyyy');
  const year = formattedDate.year;
  const isInLeapYear = DateTime.local(year).isInLeapYear;
  const currentDate = DateTime.local();
  const interval = Interval.fromDateTimes(formattedDate, currentDate);
  const years = interval.length('years');
  if (!isInLeapYear && isNaN(years)) {
    return 'Invalid date, not a leap year';
  }
  return years > 21;
}
