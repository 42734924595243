import produce from 'immer';
import { ActionTypes, TypeKeys, StateType } from './types';

export default function reducer(state: StateType, action: ActionTypes) {
  return produce<StateType>(state, draft => {
    switch (action.type) {
      case TypeKeys.CHANGE_PAGE: {
        draft.page = action.page;
        break;
      }
      case TypeKeys.UPDATE_USER_INFO: {
        const {
          firstName,
          hasFacebook,
          hasGoogle,
          isAccount,
          hasPassword,
          email,
          isCE,
          isAdmin,
        } = action;
        draft.email = email;
        draft.userInfo = {
          hasPassword,
          hasFacebook,
          hasGoogle,
          isAccount,
          firstName,
          isCE,
          isAdmin,
        };
        break;
      }
      case TypeKeys.UPDATE_NAME: {
        const { firstName, lastName } = action;
        draft.signUpFlow.firstName = firstName;
        draft.signUpFlow.lastName = lastName;
        break;
      }
      case TypeKeys.UPDATE_BIRTHDATE: {
        const { birthdate } = action;
        draft.signUpFlow.birthdate = birthdate;
        break;
      }
      case TypeKeys.UPDATE_SSO_REGISTRATION_INFO: {
        const { ssoRegistrationInput } = action;
        draft.ssoRegistrationInput = ssoRegistrationInput;
        break;
      }
    }
  });
}
