import gql from 'graphql-tag';
export type LoginMutationVariables = {
  input: {
    id: string;
    facebookToken: boolean;
    googleToken: boolean;
    isPasswordReset: boolean;
    productId: string;
    imageUrl: string;
    email: string;
    password: string;
  };
};

const mutation = gql`
  mutation UserLoginMutation($input: LoginInput!) {
    login(input: $input) {
      viewer {
        id
        isLoggedIn
        user {
          id
          role
          userId
          firstName
          lastName
          email
          permissions {
            id
            oneCommerce {
              admin {
                access
              }
            }
          }
        }
      }
    }
  }
`;

export default mutation;
