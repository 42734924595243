import * as React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { resetPassword } from './resetPasswordUtils';
import PasswordForm from '../../components/PasswordForm';
import {
  LoginMutation,
  ResetPasswordMutation,
} from '../../stateManagement/networkMutationTypes';
import styled from '@emotion/styled';

import { styles, Button } from '@onehope/design-system';
const { styled: s } = styles;

export const Layout = styled.div`
  margin: 0 auto;
  padding: 5% 16px;
  max-width: 400px;
`;
export type ResetPasswordPageComponentTypes = {
  passwordResetData: {
    email: string;
    token: string;
  };
  resetPasswordMutation: ResetPasswordMutation;
  loginMutation: LoginMutation;
};

type ResetPasswordPageProps = FormComponentProps &
  ResetPasswordPageComponentTypes;

const ResetPasswordPageComponent = ({
  form,
  passwordResetData,
  resetPasswordMutation,
  loginMutation,
}: ResetPasswordPageProps) => {
  const { email, token } = passwordResetData;
  const dispatchReset = resetPassword({
    form,
    resetPasswordMutation,
    loginMutation,
    passwordResetData: { email, token },
  });
  return (
    <Layout>
      <PasswordForm form={form} />
      <s.ButtonWrapper css>
        <Button block type="primary-regular" onClick={dispatchReset}>
          Reset Password
        </Button>
      </s.ButtonWrapper>
    </Layout>
  );
};

export default Form.create<ResetPasswordPageProps>({
  name: 'reset_password_page',
})(ResetPasswordPageComponent);
