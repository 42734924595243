// @flow
import gql from 'graphql-tag';

const mutation = gql`
  mutation ShopWithCeoDeleteMutation($input: ShopWithCeoDeleteInput!) {
    shopWithCeoDelete(input: $input) {
      user {
        id
        shoppingWithCeoUser {
          id
          userId
          firstName
        }
      }
    }
  }
`;

export default mutation;
