import gql from 'graphql-tag';

const VERIFY_EMAIL_QUERY = gql`
  query ADash_VERIFY_EMAIL($email: String) {
    viewer {
      verifyEmail(email: $email) {
        hasGoogle
        hasFacebook
        isAccount
        firstName
        hasPassword
        isCE
        isAdmin
      }
    }
  }
`;

export default VERIFY_EMAIL_QUERY;
