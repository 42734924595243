export const Errors = {
  RequiredField: 'This field is required.',
  CEPermissionsRequired: 'You must have CE permissions to login',
  AdminPermissionsRequired: 'You must have Admin permissions to login',
  LegalAgeLimitToBuyAlcohol: `Sorry, we can't sell wine to shoppers under 21. But you're welcome to shop our non-alcohol products & gifts!`,
  RegisterNewAccount:
    "We don't recognize that address. Shall we set you up with a new account? Click 'Next' to proceed with registration 😎",
  EnterValidEmail: 'Please enter a valid email.',
  EnterValidPassword: 'Please enter a valid password.',
  InvalidEmail: 'The email you entered could not be found. Please sign up!',
  AccountAlreadyExists: 'An account with this email already exists.',
  EmailAlreadyTaken: 'UserPage with email already exists.',
  EmailAlreadyTakenBySSO: 'Please login with Facebook or Google.',
  // TODO EmailAlreadyTakenByFacebookSSO & FacebookAccountAlreadyExists are the same
  EmailAlreadyTakenByFacebookSSO:
    'An account was created through Facebook. Please sign in through Facebook Login.',
  EmailAlreadyTakenByGoogleSSO:
    'An account was created through Google. Please sign in through Google Login',
  InvalidToken: 'Invalid SSO token.',
  WrongEmailOrPassword: 'Email or password is incorrect.',
  AutocreatedAccountFirstLogin: `We recognize you! Click below for a one-time password reset.`,
  VerificationNeeded: `We need to verify your email address! Click below for a one-time password reset.`,
  WrongPassword:
    'Password is invalid. Please try again or click "Forgot Password?"',
  WeakPassword:
    'Include a number or add characters to make your password stronger.',
  UnregisteredAccount: 'You must sign up before logging in.',
  GoogleAccountDoesNotExist: 'You must sign up before logging in with Google.',
  FacebookAccountDoesNotExist:
    'You must sign up before logging in with Facebook.',
  FacebookAccountAlreadyExists:
    'An account has been created through Facebook. Please sign in through Facebook Login.',
  GoogleAccountAlreadyExists:
    'An account has been created through Google. Please sign in through Google Login.',
  ResetPassword:
    'Invalid Token. Please reset your password using the login form.',
  MultiEmailAccountsUnsupported:
    'SSO account connection does not support multi-email connection.',
  ShoppingWithCeoAccountIdTaken:
    'Customer email already registered with another CEO',
  AccountCannotBeCustomer: 'This account cannot be a customer',
  TaxIdAlreadyInUse:
    'The tax ID you input is already in use by another Non-Profit',
};
