import styled from '@emotion/styled';
import {
  font,
  neutral2,
  inputFocusColor,
  errorColor,
  primaryColor,
  neutral34,
  neutral0,
} from '../../styles/cssConstants';
import TextField from '@material-ui/core/TextField';

type InputProps = {
  error?: boolean;
  hasSuccess?: boolean;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const SearchInput = styled(TextField)<InputProps>`
  & .MuiInputBase-input {
    font-family: ${font};
    height: 43.22px;
    padding: 0.15rem 16px;
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    border-radius: 100px;
    background-color: ${({ hasSuccess }: InputProps) =>
      hasSuccess ? neutral0 : neutral2};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${primaryColor} !important;
    border-radius: 100px;
  }

  & .MuiInputBase-input:focus {
    background-color: ${({ error }: InputProps) =>
      error ? '#fef8f5' : inputFocusColor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }: InputProps) =>
      error ? errorColor : primaryColor};
  }

  & .MuiFormHelperText-root {
    font-family: ${font};
    color: ${errorColor};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    margin-top: 8px;
  }
  & .MuiInputAdornment-root {
    position: absolute;
    right: 16px;
  }
  & .MuiInputAdornment-positionStart {
    left: 8px;
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }
`;

export const Label = styled.label`
  font-family: ${font};
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: ${neutral34};
`;
