// @ts-ignore
import * as React from 'react';

import FacebookLogin from '../buttons/FacebookLogin';
import GoogleLogin from '../buttons/GoogleLogin';
import { useLoginNetworkLayerValue } from '../../LoginNetworkLayer';
import { useLoginAppContextValue } from '../../stateManagement/LoginAppContext';
import { LoginPageDictionary, TypeKeys } from '../../stateManagement/types';

import { styles, Button } from '@onehope/design-system';
const { styled: s } = styles;

enum SsoProvider {
  Google = 'Google',
  Facebook = 'Facebook',
}

function backToSelectLoginMethodPage(dispatch: any) {
  return () =>
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.ENTER_EMAIL,
    });
}

function goToSSOPasswordReset(dispatch: any) {
  return () =>
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.FORGOT_PASSWORD,
    });
}

const setError = () => {};

export default function CreatedWithSsoPage() {
  const [state, dispatch] = useLoginAppContextValue();
  const { responseFacebook, responseGoogle } = useLoginNetworkLayerValue();
  const { userInfo } = state;
  const dispatchBackToSelectLoginMethodPage = backToSelectLoginMethodPage(
    dispatch,
  );
  const dispatchBackToSelectPasswordReset = goToSSOPasswordReset(dispatch);
  const ssoProvider = userInfo.hasGoogle
    ? SsoProvider.Google
    : SsoProvider.Facebook;
  const ProviderLogin = userInfo.hasGoogle ? (
    <GoogleLogin responseGoogle={responseGoogle(setError, dispatch)} />
  ) : (
    <FacebookLogin responseFacebook={responseFacebook(setError, dispatch)} />
  );
  return (
    <React.Fragment>
      <s.LoginHeader css>
        Your account was created with {ssoProvider} 🤟
      </s.LoginHeader>
      {ProviderLogin}
      <Button
        block
        type="secondary-regular"
        onClick={dispatchBackToSelectPasswordReset}
      >
        Reset Password
      </Button>
      <Button
        block
        type="tertiary-regular"
        onClick={dispatchBackToSelectLoginMethodPage}
      >
        Back
      </Button>
    </React.Fragment>
  );
}
