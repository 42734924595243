import * as React from 'react';
import { styles, Input } from '@onehope/design-system';
import Form, { FormComponentProps } from 'antd/lib/form';
import {
  passwordValidationConfig,
  validateToNextPasswordConfig,
} from './passwordUtils';

const {
  styled: s,
  // antDesignStyles: {
  //   checkBox: { CheckBoxPrimary },
  // },
} = styles;

type PasswordFormProps = {
  form: FormComponentProps['form'];
  children?: any;
};

function PasswordForm({ form, children }: PasswordFormProps) {
  // const [passwordOne, setPasswordOne] = useState('password');
  // const [passwordTwo, setPasswordTwo] = useState('password');
  // const showPasswordOne = () => setPasswordOne('text');
  // const hidePasswordOne = () => setPasswordOne('password');
  // const showPasswordTwo = () => setPasswordTwo('text');
  // const hidePasswordTwo = () => setPasswordTwo('password');
  // const checkedOne = passwordOne === 'text';
  // const checkedTwo = passwordTwo === 'text';

  const { getFieldDecorator } = form;
  // @ts-ignore
  return (
    <React.Fragment>
      <s.LoginHeader css>Create a new password 🤫</s.LoginHeader>
      <s.LoginText css>
        Passwords must include:
        <ul>
          <li>6+ characters</li>
          <li>1 special symbol or 1 number</li>
        </ul>
      </s.LoginText>
      <Form>
        <Form.Item hasFeedback>
          {getFieldDecorator(
            'password',
            passwordValidationConfig(form),
          )(
            <Input
              autoFocus
              label="Enter Password"
              kind="text"
              type="password"
              name="password"
              autoComplete="new-password"
            />,
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator(
            'confirm',
            validateToNextPasswordConfig(form),
          )(
            <Input
              label="Confirm Password"
              kind="text"
              type="password"
              name="password"
              autoComplete="new-password"
            />,
          )}
        </Form.Item>
        {children && children}
      </Form>
    </React.Fragment>
  );
}

export default PasswordForm;
