import produce from 'immer';
import { getRegisterInputVariables } from '../pages/buttons/getRegisterInputVariables';
import {
  LoginMutation,
  RegisterMutationVariables,
  RegisterMutation,
} from '../stateManagement/networkMutationTypes';
import { StateType } from '../stateManagement/types';
import { getLoginFromSsoInputVariables } from '../pages/buttons/loginMutationVariables';
import { submitLogin } from './validateAndSubmitLogin';

type ValidateAndSubmitLoginParams = {
  form: any;
  loginMutation: LoginMutation;
  state: StateType;
  registerMutation: RegisterMutation;
};

export function validateAndSubmitRegister({
  form,
  registerMutation,
  loginMutation,
  state,
}: ValidateAndSubmitLoginParams) {
  return () =>
    form.validateFields((err: any, values: { password: string }) => {
      if (!err) {
        const { password } = values;
        const inputVariables = getRegisterInputVariables(state, password);
        return registerMutation(inputVariables)
          .then((res: any) => {
            console.log('res,', res);
            if (res && res.error) {
              return form.setFields({
                password: {
                  value: form.getFieldValue('password'),
                  errors: [new Error(res.error)],
                },
              });
            }
            const { email } = state;
            return submitLogin({ email, loginMutation, password });
          })
          .catch(err => {
            console.log('register mutation error', err);
          });
      }
      return undefined;
    });
}

export type SubmitRegisterParamsPartial = {
  registerMutation: RegisterMutation;
  loginMutation: LoginMutation;
  ssoRegistrationInput?: RegisterMutationVariables;
};

export type SubmitRegisterParams = SubmitRegisterParamsPartial & {
  ssoRegistrationInput: RegisterMutationVariables;
  dateOfBirth: string;
};

export function submitSsoRegister({
  loginMutation,
  registerMutation,
  ssoRegistrationInput,
  dateOfBirth,
}: SubmitRegisterParams) {
  const inputWithBirthDate = produce(ssoRegistrationInput, draft => {
    draft.input.dateOfBirth = dateOfBirth;
  });
  return registerMutation(inputWithBirthDate)
    .then((res: any) => {
      console.log('res,', res);
      const loginInput = getLoginFromSsoInputVariables(ssoRegistrationInput);
      return loginMutation(loginInput);
    })
    .catch(err => {
      console.log('err1', err);
    });
}
