import * as React from 'react';
import {
  VerifyEmail,
  LoginMutation,
  RegisterMutation,
  SendRecoveryEmailMutation,
} from './stateManagement/networkMutationTypes';
import { GoogleOauthResponse } from './pages/buttons/GoogleOauthResponse';
import { FacebookOauthResponse } from './pages/buttons/FacebookOauthResponse';
import { ActionTypes } from './stateManagement/types';
const { useContext, createContext } = React;

export type DataProps = {
  originUrl: string;
  facebookAppId: string;
  rightImagePreset?: string;
  googleClientId: string;
  assetsUrl: string;
  loginHeader?: string;
  loginText?: string;
  verifyEmail: VerifyEmail;
  loginMutation: LoginMutation;
  registerMutation: RegisterMutation;
  sendRecoveryEmailMutation: SendRecoveryEmailMutation;
  responseGoogle: (
    setError: React.Dispatch<React.SetStateAction<string>>,
    dispatch: React.Dispatch<ActionTypes>,
  ) => (
    googleResponse: GoogleOauthResponse,
  ) => Promise<void | {
    error: any;
  }>;
  responseFacebook: (
    setError: React.Dispatch<React.SetStateAction<string>>,
    dispatch: React.Dispatch<ActionTypes>,
  ) => (
    facebookResponse: FacebookOauthResponse,
  ) => Promise<void | {
    error: any;
  }>;
  rightComponent?: any;
};

export type LoginNetworkLayerProps = DataProps & {
  children: React.ReactNode;
};

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const LoginNetworkLayerContext = createContext<DataProps>();

export const useLoginNetworkLayerValue = () =>
  useContext<DataProps>(LoginNetworkLayerContext);

export default function LoginNetworkLayer({
  children,
  facebookAppId,
  googleClientId,
  assetsUrl,
  originUrl,
  loginHeader,
  loginText,
  verifyEmail,
  loginMutation,
  registerMutation,
  sendRecoveryEmailMutation,
  responseGoogle,
  responseFacebook,
  rightComponent,
}: LoginNetworkLayerProps) {
  return (
    <LoginNetworkLayerContext.Provider
      value={{
        originUrl,
        verifyEmail,
        facebookAppId,
        googleClientId,
        assetsUrl,
        loginHeader,
        loginText,
        loginMutation,
        registerMutation,
        sendRecoveryEmailMutation,
        responseGoogle,
        responseFacebook,
        rightComponent,
      }}
    >
      {children}
    </LoginNetworkLayerContext.Provider>
  );
}
