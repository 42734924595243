import { RegisterMutationVariables } from './networkMutationTypes';

export enum LoginPageDictionary {
  SELECT_LOGIN_METHOD,
  ENTER_EMAIL,
  ENTER_PASSWORD,
  CREATED_WITH_SSO,
  ENTER_NAME,
  ENTER_AGE,
  CREATE_PASSWORD,
  FORGOT_PASSWORD,
  CHECK_YOUR_EMAIL,
}

type SignUpFlow = {
  birthdate?: string;
  firstName?: string;
  lastName?: string;
};

export type StateType = {
  page: LoginPageDictionary;
  signUpFlow: SignUpFlow;
  email: string;
  userInfo: {
    hasPassword?: boolean;
    hasFacebook?: boolean;
    hasGoogle?: boolean;
    isAccount?: boolean;
    firstName?: string;
    isCE?: boolean;
    isAdmin?: boolean;
  };
  ssoRegistrationInput?: RegisterMutationVariables;
};

// Following is taken from: https://spin.atomicobject.com/2017/07/24/redux-action-pattern-typescript/

export enum TypeKeys {
  CHANGE_PAGE = 'CHANGE_PAGE',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  UPDATE_NAME = 'UPDATE_NAME',
  UPDATE_BIRTHDATE = 'UPDATE_BIRTHDATE',
  UPDATE_SSO_REGISTRATION_INFO = 'UPDATE_SSO_REGISTRATION_INFO',
}

export type ChangePageAction = {
  type: TypeKeys.CHANGE_PAGE;
  page: LoginPageDictionary;
};

export type VerifyEmailCallbackProps = {
  isAccount: boolean;
  hasFacebook: boolean;
  hasGoogle: boolean;
  firstName: string;
  email: string;
  hasPassword: boolean;
  isCE: boolean;
  isAdmin: boolean;
};

export type UpdateAccountTokensAction = VerifyEmailCallbackProps & {
  type: TypeKeys.UPDATE_USER_INFO;
};

export type UpdateNameAction = {
  type: TypeKeys.UPDATE_NAME;
  firstName: string;
  lastName: string;
};

export type UpdateBirthdateAction = {
  type: TypeKeys.UPDATE_BIRTHDATE;
  birthdate: string;
};

export type UpdateSsoRegistrationInfoAction = {
  type: TypeKeys.UPDATE_SSO_REGISTRATION_INFO;
  ssoRegistrationInput?: RegisterMutationVariables;
};

export type ActionTypes =
  | ChangePageAction
  | UpdateAccountTokensAction
  | UpdateNameAction
  | UpdateBirthdateAction
  | UpdateSsoRegistrationInfoAction;
