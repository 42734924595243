// @ts-ignore
import * as React from 'react';
import styled from '@emotion/styled';
import { emailConfig } from '../buttons/LoginMethodButton';
import FacebookLogin from '../buttons/FacebookLogin';
import GoogleLogin from '../buttons/GoogleLogin';
import { useLoginNetworkLayerValue } from '../../LoginNetworkLayer';
import { useLoginAppContextValue } from '../../stateManagement/LoginAppContext';
import {
  LoginPageDictionary,
  TypeKeys,
  ActionTypes,
} from '../../stateManagement/types';

import { styles, Button } from '@onehope/design-system';

const { useState } = React;
const {
  cssConstants: { errorColor },
} = styles;

const ButtonLayout = styled.div`
  text-align: center;
`;
const ErrorMessage = styled.span`
  text-align: center;
  font-size: 12px;
  color: ${errorColor};
`;

function goToEmailPage(dispatch: React.Dispatch<ActionTypes>) {
  return () =>
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.ENTER_EMAIL,
    });
}

// Simplified from: https://stackoverflow.com/questions/37591279/detect-if-user-is-using-webview-for-android-ios-or-a-regular-browser
const isWebview = () => {
  if (typeof window !== 'undefined') {
    const navigator = window?.navigator as any;
    const standalone = navigator?.standalone;

    const userAgent = navigator?.userAgent?.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    const iosWebview = ios && !standalone && !safari;
    const androidWebview = !ios && userAgent?.includes('wv');

    return iosWebview || androidWebview;
  }

  return false;
};

export default function Buttons() {
  const [error, setError] = useState('');
  const [, dispatch] = useLoginAppContextValue();
  const { responseFacebook, responseGoogle } = useLoginNetworkLayerValue();

  const webview = isWebview();

  return (
    <ButtonLayout>
      <Button
        block
        type="email"
        data-cypress="login-with-email"
        onClick={goToEmailPage(dispatch)}
      >
        {emailConfig.text}
      </Button>
      {!webview && (
        <FacebookLogin
          responseFacebook={responseFacebook(setError, dispatch)}
        />
      )}
      {!webview && (
        <GoogleLogin responseGoogle={responseGoogle(setError, dispatch)} />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ButtonLayout>
  );
}
