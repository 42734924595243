import gql from 'graphql-tag';


export type AccountPasswordRecoveryMutationVariables = {
  input: {
    email: string;
    originUrl: string;
  };
};


export const mutation = gql`
  mutation UserRecoverPasswordMutation($input: UserRecoverPasswordInput!) {
    userRecoverPassword(input: $input) {
      user {
        id
        userId
        email
        firstName
        lastName
        role
        password
      }
    }
  }
`;

export default mutation;
