import * as React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { styles, Button } from '@onehope/design-system';
import { useLoginNetworkLayerValue } from '../../../LoginNetworkLayer';
import { useLoginAppContextValue } from '../../../stateManagement/LoginAppContext';
import { submitLogin } from '../../../utils/validateAndSubmitLogin';
import {
  StateType,
  LoginPageDictionary,
  TypeKeys,
} from '../../../stateManagement/types';
import PasswordForm from '../../../components/PasswordForm';
import {
  RegisterMutation,
  LoginMutation,
} from '../../../stateManagement/networkMutationTypes';
import { getRegisterInputVariables } from '../../buttons/getRegisterInputVariables';

const { styled: s } = styles;

type PasswordForm = FormComponentProps<{ password: string }>;

type CreatePasswordPageProps = PasswordForm & {};

function goToEnterAge(dispatch: any) {
  return () => {
    dispatch({
      type: TypeKeys.CHANGE_PAGE,
      page: LoginPageDictionary.ENTER_AGE,
    });
  };
}

type SubmitRegistrationParams = {
  form: PasswordForm['form'];
  loginMutation: LoginMutation;
  state: StateType;
  registerMutation: RegisterMutation;
};

function submitRegistration({
  form,
  registerMutation,
  loginMutation,
  state,
}: SubmitRegistrationParams) {
  form.validateFields((err: any, values: { password: string }) => {
    if (!err) {
      const { password } = values;
      const inputVariables = getRegisterInputVariables(state, password);
      return registerMutation(inputVariables)
        .then((res: any) => {
          console.log('res,', res);
          if (res && res.error) {
            return form.setFields({
              password: {
                value: form.getFieldValue('password'),
                errors: [new Error(res.error)],
              },
            });
          }
          const { email } = state;
          return submitLogin({ email, loginMutation, password });
        })
        .catch(err => {
          console.log('err1', err);
        });
    }
    return undefined;
  });
}

function CreatePasswordPageComponent({ form }: CreatePasswordPageProps) {
  const [state, dispatch] = useLoginAppContextValue();
  const [submitting, setSubmitting] = React.useState(false);
  const { registerMutation, loginMutation } = useLoginNetworkLayerValue();
  const dispatchRegistration = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    await submitRegistration({
      form,
      registerMutation,
      loginMutation,
      state,
    });
    setSubmitting(false);
  };

  const dispatchGoToEnterAge = goToEnterAge(dispatch);
  return (
    <React.Fragment>
      <PasswordForm form={form}>
        <s.ButtonWrapper css>
          <Button
            block
            type="primary-regular"
            htmlType="submit"
            onClick={dispatchRegistration}
          >
            Create Account
          </Button>
          <Button type="tertiary-regular" onClick={dispatchGoToEnterAge}>
            Back
          </Button>
        </s.ButtonWrapper>
      </PasswordForm>
    </React.Fragment>
  );
}

export default Form.create({
  name: 'create_password_page',
})(CreatePasswordPageComponent);
