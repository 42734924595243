import {
  LoginMutationVariables,
  RegisterMutationVariables,
} from '../../stateManagement/networkMutationTypes';
import { FacebookOauthResponse } from './FacebookOauthResponse';
import { GoogleOauthResponse } from './GoogleOauthResponse';

export function getGoogleInputVariables(
  response: GoogleOauthResponse
): LoginMutationVariables {
  return {
    input: {
      id: '',
      password: response.tokenId,
      isPasswordReset: false,
      productId: '',
      email: response.profileObj.email,
      googleToken: true,
      facebookToken: false,
      imageUrl: response.profileObj.imageUrl,
    },
  };
}

export function getLoginFromSsoInputVariables(
  registerInput: RegisterMutationVariables
): LoginMutationVariables {
  const {
    password,
    email,
    imageUrl,
    facebookToken,
    googleToken,
  } = registerInput.input;
  return {
    input: {
      id: '',
      password,
      isPasswordReset: false,
      productId: '',
      email,
      googleToken: googleToken || false,
      facebookToken: facebookToken || false,
      imageUrl: imageUrl || '',
    },
  };
}

export function getFacebookInputVariables(
  response: FacebookOauthResponse
): LoginMutationVariables {
  const { email, accessToken, picture } = response;
  return {
    input: {
      id: '',
      password: accessToken,
      isPasswordReset: false,
      productId: '',
      email,
      googleToken: false,
      facebookToken: true,
      imageUrl: picture.data.url,
    },
  };
}

export function getEmailInputVariables(email: string, password: string) {
  return {
    input: {
      id: '',
      facebookToken: false,
      googleToken: false,
      isPasswordReset: false,
      productId: '',
      imageUrl: '',
      email,
      password,
    },
  };
}
