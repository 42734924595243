import * as React from 'react';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system';

const {
  cssConstants: { font, googleColor, linkColor, primaryColor },
} = styles;

export const facebookConfig = {
  color: linkColor,
  text: 'Sign in with Facebook',
  iconUrl: '',
};

export const emailConfig = {
  color: primaryColor,
  text: 'Sign in with email',
  iconUrl: '',
};

export const googleConfig = {
  color: googleColor,
  text: 'Sign in with Google',
  iconUrl: '',
};

type LoginMethodButtonProps = LoginButtonProps & {
  text: string;
  onClick: () => void;
};

type LoginButtonProps = {
  color: string;
  iconUrl: string;
  backgroundImage: string;
};

const LoginButton = styled.button<LoginButtonProps>`
  background-color: ${(props: LoginButtonProps) => props.color};
  color: #ffffff;
  font-family: ${font};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 1px;
  line-height: 20px;
  height: 40px;
  width: 288px;
  text-align: center;
  border-radius: 4px;
  margin: 10px 0;
  text-transform: uppercase;
  background-image: url(${(props: LoginButtonProps) => props.backgroundImage});
`;

// const Icon = styled.div<{ iconUrl: string }>`
//   background-image: ${props => props.iconUrl};
//   padding: 16px;
// `;

export default function LoginMethodButton({
  iconUrl,
  text,
  color,
  onClick,
  backgroundImage,
}: LoginMethodButtonProps) {
  return (
    <LoginButton
      backgroundImage={backgroundImage}
      color={color}
      onClick={onClick}
      iconUrl={iconUrl}
    >
      {text}
    </LoginButton>
  );
}
