import gql from 'graphql-tag';

const mutation = gql`
  mutation CustomerEditMutation($input: CustomerEditInput!) {
    customerEdit(input: $input) {
      customer {
        id
        accountId
        firstName
        lastName
        email
      }
    }
  }
`;

export default mutation;
