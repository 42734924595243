import gql from 'graphql-tag';

export type RegisterMutationVariables = {
  input: {
    id: string;
    email: string;
    facebookToken?: boolean;
    googleToken?: boolean;
    password: string;
    firstName: string;
    lastName: string;
    imageUrl?: string;
  };
};

const mutation = gql`
  mutation Registermutation($input: RegisterInput!) {
    register(input: $input) {
      viewer {
        id
        user {
          id
          role
          userId
          firstName
          lastName
          permissions {
            id
            oneCommerce {
              ceo {
                access
              }
            }
          }
        }
      }
    }
  }
`;

export default mutation;
