// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-info-tsx": () => import("./../../../src/pages/account-info.tsx" /* webpackChunkName: "component---src-pages-account-info-tsx" */),
  "component---src-pages-addresses-tsx": () => import("./../../../src/pages/addresses.tsx" /* webpackChunkName: "component---src-pages-addresses-tsx" */),
  "component---src-pages-all-donations-tsx": () => import("./../../../src/pages/all-donations.tsx" /* webpackChunkName: "component---src-pages-all-donations-tsx" */),
  "component---src-pages-customer-orders-tsx": () => import("./../../../src/pages/customer-orders.tsx" /* webpackChunkName: "component---src-pages-customer-orders-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-payments-tsx": () => import("./../../../src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */),
  "component---src-pages-personal-orders-tsx": () => import("./../../../src/pages/personal-orders.tsx" /* webpackChunkName: "component---src-pages-personal-orders-tsx" */),
  "component---src-pages-promos-tsx": () => import("./../../../src/pages/promos.tsx" /* webpackChunkName: "component---src-pages-promos-tsx" */),
  "component---src-pages-recovery-tsx": () => import("./../../../src/pages/recovery.tsx" /* webpackChunkName: "component---src-pages-recovery-tsx" */),
  "component---src-pages-subscriptions-tsx": () => import("./../../../src/pages/subscriptions.tsx" /* webpackChunkName: "component---src-pages-subscriptions-tsx" */)
}

