import gql from 'graphql-tag';

export const mutation = gql`
  mutation UserUpdateInfoMutation($input: UserUpdateInfoInput!) {
    userUpdateInfo(input: $input) {
      user {
        id
        email
        firstName
        lastName
        password
        mobilePhone
        isResettingPassword
        profile {
          id
          city
          state
          zip
          summary
        }
      }
    }
  }
`;

export default mutation;
