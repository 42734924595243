import isAlpha from 'validator/lib/isAlpha';
import isMobilePhone from 'validator/lib/isMobilePhone';

export const whitelistedPaths = {
  '/promos': true,
  '/donations': true,
  '/design': true,
} as { [key: string]: boolean };

export function getCurrentUserId(location: any) {
  const { search } = location;
  const hasCorrectParams = search.includes('user=');
  return hasCorrectParams && search !== '' ? search.split('?user=')[1] : false;
}

export const statesAbbrev = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export function isStateAbbrev(abbrev: string) {
  return statesAbbrev.indexOf(abbrev) !== -1;
}

export function removeSpaces(text: string): string {
  return text.replace(/\s/g, '');
}

function isNotAlpha(text: string): boolean {
  return !isAlpha(removeSpaces(text));
}

export function checkContainsPOBox(line: string): boolean {
  const pattern = /^box[^a-z]|(p[-. ]?o.?[- ]?|post office )b(.|ox)/i;
  const noExtraSpace = line.replace(/\s+/g, ' ').trim();
  if (noExtraSpace.match(pattern)) return true;
  const noPunctuation = noExtraSpace.replace(
    /[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
    '',
  );
  if (noPunctuation.match(pattern)) return true;
  return false;
}

export function isValidUSZip(usZip: string): boolean {
  return /^\d{5}(-\d{4})?$/.test(usZip);
}

export function checkContainsNumber(addressLineOne: string) {
  const containsDigits = /[0123456789]/.test(addressLineOne);
  const containsNumbers = /one/.test(addressLineOne.toLowerCase());
  return containsNumbers || containsDigits;
}

export function checkContainsLetter(addressLineOne: string) {
  return /[abcdefghijklmnopqrstuvwxyz]/.test(addressLineOne.toLowerCase());
}

export function checkCountry(country: string) {
  if (!country) {
    return true;
  }
  const lowerCountry = country.toLowerCase();
  switch (true) {
    case lowerCountry === 'usa':
    case lowerCountry === 'us':
    case lowerCountry === 'united states':
    case lowerCountry === '':
    case lowerCountry === ' ':
      return true;
    default:
      return false;
  }
}

export const redirectToLoginV2 = (redirectUri: string) => {
  window.location.href = `${process.env.GATSBY_MEGALITH_URL}/login?redirect_uri=${redirectUri}`;
};
