import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { Button, Popconfirm } from 'antd';
import styled from '@emotion/styled';
import moment from 'moment';
import LineItem from './LineItem';
import AutoShipCancelMutation from '../../../mutations/Autoship/AutoShipCancelMutation';

export type AutoshipCardProps = {
  autoShipId: number;
  autoShipName: string;
  nextPaymentDate: string;
  stripeSubscriptionId: string;
  subscriptionStatus: string;
  createDate: string;
  lastModifiedDate: string;
  billingIntervalUnit: string;
  billingIntervalCount: number;
  timesRenewed: number;
  pendingCuratedPack: boolean;
  winePack: {
    wineNamesFull: Array<string>;
  };
};

const ButtonContainer = styled.div`
  margin-top: 50px;
`;

const buildStripeLink = (subscriptionId: string) => {
  if (process.env.NODE_ENV === 'production') {
    return `https://dashboard.stripe.com/subscriptions/${subscriptionId}`;
  }
  return `https://dashboard.stripe.com/test/subscriptions/${subscriptionId}`;
};

const getPendingReason = ({
  pendingCuratedPack,
}: AutoshipCardProps): string => {
  if (pendingCuratedPack) {
    return `Pending until next quarter's wine club is published`;
  }
  return `Pending creation (or something went wrong)`;
};

const formatDate = (date: string) => {
  return moment
    .utc(date)
    .local()
    .format('ll');
};

const AutoshipCard = (props: AutoshipCardProps) => {
  const {
    autoShipId,
    autoShipName,
    billingIntervalCount,
    billingIntervalUnit,
    createDate,
    lastModifiedDate,
    nextPaymentDate,
    pendingCuratedPack,
    stripeSubscriptionId,
    subscriptionStatus,
    timesRenewed,
    winePack,
  } = props;

  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelComplete, setCancelComplete] = useState(false);

  const onMutationError = (e: Error) => {
    console.error('Mutation error', e);
    setIsCancelling(false);
  };

  const onCancelMutationCompleted = () => {
    setIsCancelling(false);
    setCancelComplete(true);
  };

  const cancelSubscription = (autoshipCancel: (input: object) => void) => {
    if (isCancelling) return null;
    setIsCancelling(true);
    return autoshipCancel({
      variables: {
        input: {
          autoShipId,
        },
      },
    });
  };

  const stripeLink = stripeSubscriptionId ? (
    <a
      href={buildStripeLink(stripeSubscriptionId)}
      target="_blank"
      rel="noopener noreferrer"
    >
      View in Stripe
    </a>
  ) : (
    <em>{getPendingReason(props)}</em>
  );

  const cancelButton = (
    <>
      {!cancelComplete && (
        <ButtonContainer>
          <Mutation
            mutation={AutoShipCancelMutation}
            onError={onMutationError}
            onCompleted={onCancelMutationCompleted}
          >
            {(autoshipCancel: (input: object) => void) => (
              <Popconfirm
                title={`Cancel ${autoShipName} for user? They will be notified.`}
                cancelText="No"
                okText="Yes"
                onConfirm={() => cancelSubscription(autoshipCancel)}
              >
                <Button
                  style={{ marginTop: 20 }}
                  htmlType="button"
                  type="danger"
                  title="Cancel"
                  size="default"
                  ghost={true}
                  disabled={isCancelling}
                >
                  Cancel {autoShipName}
                </Button>
              </Popconfirm>
            )}
          </Mutation>
        </ButtonContainer>
      )}

      {cancelComplete && <b>{autoShipName} has been canceled.</b>}
    </>
  );

  let wineNamesFull: Array<string> = [];
  if (winePack) {
    ({ wineNamesFull } = winePack);
  }

  return (
    <div style={{ marginTop: 30 }}>
      <LineItem
        labelText={'Subscription Status'}
        valueText={subscriptionStatus}
      />
      <LineItem labelText="Subscription Name" valueText={autoShipName} />
      <LineItem labelText="Autoship ID" valueText={autoShipId} />

      <LineItem labelText="Stripe Subscription" valueText={stripeLink} />
      <LineItem
        labelText="Included Wines"
        valueText={
          wineNamesFull.length ? '' : <em>{getPendingReason(props)}</em>
        }
      />

      <ul>
        {wineNamesFull.map(wine => (
          <li key={wine.toLowerCase().replace(/[^a-z]/g, '')}>{wine}</li>
        ))}
      </ul>

      <LineItem
        labelText="Frequency"
        valueText={`Every ${billingIntervalCount} ${billingIntervalUnit}(s)`}
      />
      <LineItem labelText="Create Date" valueText={formatDate(createDate)} />
      <LineItem
        labelText="Updated Date"
        valueText={formatDate(lastModifiedDate)}
      />
      <LineItem
        labelText="Next Bill Date"
        valueText={formatDate(nextPaymentDate)}
      />
      <LineItem labelText="Times Renewed" valueText={timesRenewed} />

      {subscriptionStatus !== 'canceled' && cancelButton}
      {subscriptionStatus === 'canceled' && (
        <ButtonContainer>
          <Button
            style={{ marginTop: 20 }}
            htmlType="button"
            type="danger"
            title="Already canceled"
            size="default"
            ghost={true}
            disabled={true}
          >
            Already canceled
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
};

export default AutoshipCard;
