import React from 'react';

import { ApolloConsumer } from 'react-apollo';

import { ResetPasswordPage } from '@onehope/login';
import {
  suffix,
  loginMutation,
  resetPasswordMutation,
} from '../utils/authentication';

export default function Recovery(props: any) {
  const { GATSBY_S3_ASSETS } = process.env;
  if (!GATSBY_S3_ASSETS) return <div>We can't pull images, {suffix}</div>;
  const { location } = props;
  const redirectUri =
    new URLSearchParams(location.search).get('redirect_uri') || '/';
  const email = new URLSearchParams(location.search).get('email');
  const token = new URLSearchParams(location.search).get('token');
  if (email && token) {
    const passwordResetData: { email: string; token: string } = {
      email,
      token,
    };
    return (
      <ApolloConsumer>
        {client => (
          <ResetPasswordPage
            passwordResetData={passwordResetData}
            loginMutation={loginMutation(client, redirectUri)}
            resetPasswordMutation={resetPasswordMutation(client)}
          />
        )}
      </ApolloConsumer>
    );
  }
  return null;
}
