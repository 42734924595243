import gql from 'graphql-tag';


export type ResetPasswordMutationVariables = {
  input: {
    email: string;
    token: string;
    password: string;
  };
};

export const mutation = gql`
  mutation UserResetPasswordMutation($input: UserResetPasswordInput!) {
    userResetPassword(input: $input) {
      user {
        id
        userId
        email
        firstName
        lastName
        role
        password
      }
    }
  }
`;

export default mutation;
