import * as React from 'react';

import { useLoginAppContextValue } from './stateManagement/LoginAppContext';
import { LoginPageDictionary } from './stateManagement/types';

import EnterEmailPage from './pages/EnterEmailPage/EnterEmailPage';
import SelectLoginMethod from './pages/SelectLoginMethodPage/SelectLoginMethodPage';
import CreatedWithSsoPage from './pages/CreatedWithSsoPage/CreatedWithSsoPage';
import EnterPasswordPage from './pages/EnterPasswordPage/EnterPasswordPage';
import EnterNamePage from './pages/RegistrationFlow/EnterNamePage/EnterNamePage';
import EnterAgePage from './pages/RegistrationFlow/EnterAgePage/EnterAgePage';
import CreatePasswordPage from './pages/RegistrationFlow/CreatePasswordPage/CreatePasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import CheckYourEmail from './pages/CheckYourEmailPage/CheckYourEmailPage';

export default function Stepper() {
  const [state] = useLoginAppContextValue();
  const {
    ENTER_EMAIL,
    ENTER_NAME,
    ENTER_AGE,
    CREATE_PASSWORD,
    CREATED_WITH_SSO,
    ENTER_PASSWORD,
    FORGOT_PASSWORD,
    CHECK_YOUR_EMAIL,
  } = LoginPageDictionary;
  switch (state.page) {
    // Login Flow
    case ENTER_EMAIL: {
      return <EnterEmailPage />;
    }
    case ENTER_PASSWORD: {
      return <EnterPasswordPage />;
    }
    // Registration Flow
    case ENTER_NAME: {
      return <EnterNamePage />;
    }
    case ENTER_AGE: {
      return <EnterAgePage />;
    }
    case CREATE_PASSWORD: {
      return <CreatePasswordPage />;
    }
    // Reset Password
    case FORGOT_PASSWORD: {
      return <ForgotPasswordPage />;
    }
    case CHECK_YOUR_EMAIL: {
      return <CheckYourEmail />;
    }
    // Misc Flow
    case CREATED_WITH_SSO: {
      return <CreatedWithSsoPage />;
    }
    // First Page
    default:
      return <SelectLoginMethod />;
  }
}
