import React, { Component, ReactNode } from 'react';
import Layout from 'antd/lib/layout';
import LocaleProvider from 'antd/lib/locale-provider';
import enUS from 'antd/lib/locale-provider/en_US';
import { navigate } from 'gatsby';
import isUUID from 'validator/lib/isUUID';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import SiderNav from '../sider/sider';
import Header from '../header/header';
import Content from '../content/content';
import { whitelistedPaths } from '../../../utils/utils';
import { Query } from 'react-apollo';
import _get from 'lodash/get';
import './layout.css';
import GET_USER_PERMISSIONS_QUERY from '../../../queries/UserPermissions';
import LoginV2Redirect from './loginRedirectV2';

interface AppLayoutProps {
  children: ReactNode;
  selectedUserId: string;
  pathname: string;
}

interface AppLayoutState {
  collapsed: boolean;
}

class AppLayout extends Component<AppLayoutProps, AppLayoutState> {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  getSelectedMenuItem = () => {
    const { pathname } = this.props;
    switch (true) {
      case pathname.includes('/account-info'):
      case pathname.includes('/orders'):
      case pathname.includes('/personal-orders'):
      case pathname.includes('/customer-orders'):
      case pathname.includes('/addresses'):
      case pathname.includes('/payments'):
      case pathname.includes('/subscriptions'):
      case pathname.includes('/events'):
      case pathname.includes('/customers'):
      case pathname.includes('/all-donations'):
        return ['1'];
      case pathname.includes('/promos'):
        return ['2'];
      case pathname.includes('/donations'):
        return ['3'];
      case pathname.includes('/products'):
        return ['1'];
      default:
        return ['0'];
    }
  };

  render() {
    const { children } = this.props;
    const { collapsed } = this.state;
    const defaultSelectedKeys = this.getSelectedMenuItem();
    const { selectedUserId } = this.props;
    const userHasCorrectFormat = isUUID(selectedUserId.toString());
    const { pathname } = this.props;
    // @ts-ignore
    if (
      typeof window !== `undefined` &&
      !whitelistedPaths[pathname] &&
      pathname !== '/' &&
      !userHasCorrectFormat
    ) {
      navigate('/');
      return null;
    }
    return (
      <Query query={GET_USER_PERMISSIONS_QUERY}>
        {({ loading, error, data }) => {
          if (error) return `Error! ${error.message}`;
          let isLoggedInAsAdmin = false;
          if (loading) return null;
          if (!loading) {
            isLoggedInAsAdmin =
              !data || !data.viewer || !data.viewer.user
                ? false
                : _get(
                    data.viewer.user,
                    'permissions.oneCommerce.admin.access',
                  );
            if (!isLoggedInAsAdmin) {
              return <LoginV2Redirect />;
            }
          }
          return (
            <LocaleProvider locale={enUS}>
              <Layout>
                <SiderNav
                  collapsed={collapsed}
                  selectedUserId={selectedUserId}
                  defaultSelectedKeys={defaultSelectedKeys}
                />
                <Layout>
                  <Header collapsed={collapsed} toggle={this.toggle} />
                  <Content
                    pathname={pathname}
                    children={children}
                    selectedUserId={selectedUserId}
                  />
                </Layout>
              </Layout>
            </LocaleProvider>
          );
        }}
      </Query>
    );
  }
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(AppLayout);
