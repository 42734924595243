import styled from '@emotion/styled';
import { css } from 'emotion';
import {
  neutral0,
  neutral2,
  neutral34,
  inputFocusColor,
  primaryColor,
  textColor,
  font,
} from '../../styles/cssConstants';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { SvgCorner } from './corner';

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const MuiTextArea = styled(TextareaAutosize)`
  /* min-height: 16px; */
`;

type WrapProps = {
  validInput: boolean;
};

export const Wrap = styled.div<{ validInput: boolean }>`
  position: relative;
  display: inline-block;
  max-width: 432px;
  width: 100%;
  :after {
    content: '';
    border-top: ${(props: WrapProps) =>
      props.validInput ? `14px solid ${neutral0}` : `14px solid ${neutral2}`};
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    :focus {
      border-top: 14px solid ${inputFocusColor};
      border-left: 14px solid ${inputFocusColor};
      border-right: 14px solid ${inputFocusColor};
    }
    -webkit-transform: rotate(-45deg);
    z-index: 1;
    position: absolute;
    right: -8px;
    bottom: 2px;
    pointer-events: none;
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ResizeIcon = styled(SvgCorner)`
  position: absolute;
  bottom: 4px;
  right: 0;
  pointer-events: none;
  z-index: 2;
`;

export const textAreaBaseStyle = css`
  width: 100%;
  background-color: ${neutral2};
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${primaryColor};
  /* font-family: GothamHTF; */
  font-size: 14px;
  font-style: italic;
  line-height: 1.43;
  color: #6d7278;
  padding: 16px;
  min-height: 53px;
  :focus {
    background-color: ${inputFocusColor};
    outline: none !important;
  }
  z-index: 1;
  position: relative;
  z-index: 1;
`;

export const validStyle = css`
  background-color: ${neutral0};
  font-style: normal;
  color: ${textColor};
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const Label = styled.label`
  height: 18px;
  font-family: ${font};
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  color: ${neutral34};
  margin-right: 8px;
`;

export const WordCount = styled.div`
  max-width: 432px;
  height: 18px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.64;
  text-align: right;
  color: ${neutral34};
  margin-bottom: 40px;
`;
