import gql from 'graphql-tag';

const DISABLE_ADDRESS_BOOK = gql`
  mutation AddressBookDisable($input: AddressBookDisableInput!) {
    addressBookDisable(input: $input) {
      deletedAddressId
      addressBook {
        id
        entries(first: 1000) {
          edges {
            __typename
            cursor
            node {
              id
              address {
                id
                disabled
                firstName
                lastName
                street
                addressLineOne
                addressLineTwo
                city
                state
                zip
                recipientBirthDate
                addressId
                country
                phoneNumber
                emailAddress
                specialInstructions
                nickname
                companyName
              }
            }
          }
        }
      }
    }
  }
`;

export default DISABLE_ADDRESS_BOOK;
