import {
  LoginMutation,
  ResetPasswordMutation,
} from '../../stateManagement/networkMutationTypes';
import { getEmailInputVariables } from '../buttons/loginMutationVariables';

type SubmitResetPasswordParams = {
  form: any;
  resetPasswordMutation: ResetPasswordMutation;
  loginMutation: LoginMutation;
  passwordResetData: {
    email: string;
    token: string;
  };
};
function getInputVariables(email: string, token: string, password: string) {
  return {
    input: {
      email,
      token,
      password,
    },
  };
}

export function resetPassword({
  form,
  resetPasswordMutation,
  loginMutation,
  passwordResetData,
}: SubmitResetPasswordParams) {
  return () =>
    form.validateFields((err: any, values: { password: string }) => {
      if (!err) {
        const { password } = values;
        const { email, token } = passwordResetData;
        const inputVariables = getInputVariables(email, token, password);
        return resetPasswordMutation(inputVariables)
          .then((res: any) => {
            console.log('password reset,', res);
            if (res && res.error) {
              return form.setFields({
                password: {
                  value: form.getFieldValue('password'),
                  errors: [new Error(res.error)],
                },
              });
            }
            const loginInputVariables = getEmailInputVariables(email, password);
            return loginMutation(loginInputVariables)
              .then((res: any) => {
                console.log('logged in res,', res);
              })
              .catch(err => {
                console.log('logged in err1', err);
              });
          })
          .catch(err => {
            console.log('password reset err1', err);
          });
      }
      return undefined;
    });
}
