import gql from 'graphql-tag';

const GET_EVENTS_QUERY = gql`
  query ADash_EventsQuery($accountId: String, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        events(first: 10000) {
          edges {
            node {
              id
              donationId
              eventId
              eventDate
              closeDate
              hostFirstName
              hostLastName
              hostEmail
              hostAccountId
              status
              trinityPartyStatus
              trinityPartyId
              hostKitOrderNumbers
              hostKitQuantity
              hostRewardsOrderIds
              donationInfo {
                id
                ordersSubTotal
                donationAmount
                donationType
                status
                lobMemo
              }
              hostRewards {
                code
                promoCodeId
                loadedBalance
                storeBalance
                usableByAccountId
                usableByEmails
                expiresOn
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_EVENTS_QUERY;
