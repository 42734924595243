import gql from 'graphql-tag';

const GET_SUBSCRIPTIONS_QUERY = gql`
  query ADash_SubscriptionsQuery(
    $accountId: String
    $csPortal: Boolean!
    $includeCanceled: Boolean
  ) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        autoShips(includeCanceled: $includeCanceled) {
          autoShipId
          autoShipName
          nextPaymentDate
          stripeSubscriptionId
          subscriptionStatus
          createDate
          lastModifiedDate
          billingIntervalUnit
          billingIntervalCount
          timesRenewed
          pendingCuratedPack
          winePack {
            wineNamesFull
          }
        }
        subscriptions {
          id
          preferredCustomer {
            subscriptionStatus
            enrollmentStatus
            grapefulCreationDate
            savings
            subscriptionId
            grapefulRenewalDate
            grapefulExpirationDate
            paymentStatus
            orderId
            totalNumberOrderItems
            orderStatus
            shippingAddress {
              addressId
              addressLineOne
              addressLineTwo
              city
              companyName
              country
              firstName
              id
              lastName
              specialInstructions
              phoneNumber
              recipientBirthDate
              state
              zip
              nickname
            }
            paymentInfo {
              nickname
              brand
              last4
              expMonth
              expYear
            }
          }
          wineClub {
            subscriptionStatus
            enrollmentStatus
            marisCreationDate
            marisRenewalDate
            savings
            shippingAddress {
              addressId
              addressLineOne
              addressLineTwo
              city
              companyName
              country
              firstName
              id
              lastName
              specialInstructions
              phoneNumber
              recipientBirthDate
              state
              zip
              nickname
            }
            paymentInfo {
              nickname
              brand
              last4
              expMonth
              expYear
            }
            subscriptionId
          }
          CE {
            subscriptionStatus
            CECreationDate
            CERenewalDate
            paymentInfo {
              nickname
              brand
              last4
              expMonth
              expYear
            }
            planId
            enrollmentStatus
            paymentStatus
            trinityEnrollDate
            subscriptionId
          }
        }
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS_QUERY;
