import React from 'react';
import SubscriptionCards from './SubscriptionCards/SubscriptionCards';

interface SubscriptionsProps {
  subscriptions: any;
  selectedUserId: string;
}

const Subscriptions: React.FunctionComponent<SubscriptionsProps> = ({
  subscriptions,
  selectedUserId,
  autoShips,
}) => (
  <SubscriptionCards
    subscriptions={subscriptions}
    autoShips={autoShips}
    selectedUserId={selectedUserId}
  />
);
export default Subscriptions;
